// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Container,
//   Typography,
//   Paper,
//   Box,
// } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import SaveIcon from "@mui/icons-material/Save";
// import DevicesIcon from "@mui/icons-material/Devices";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#1976d2",
//     },
//     secondary: {
//       main: "#dc004e",
//     },
//   },
//   typography: {
//     h5: {
//       fontWeight: 600,
//     },
//   },
// });

// const DeviceForm = () => {
//   const userId = localStorage.getItem("userId")
//   const [deviceData, setDeviceData] = useState({
//     device_name: "",
//     ip_address: "",
//     mac_id: "",
//     serial_number: "",
//     description: "",
//     user: userId, 
//   });
//   const [error, setError] = useState("");
  

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setDeviceData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_PUBLIC_URL}/api/cimdevices/create/`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(deviceData),
//         },
//       );
//       if (!response.ok) {
//         const errorData = await response.json();
//         console.log("Error response data:", errorData);
//         throw new Error("Failed to save device data");
//       }
//       const result = await response.json();
//       console.log("Device data saved:", result);
//       // Reset form after successful submission
//       setDeviceData({
//         device_name: "",
//         ip_address: "",
//         mac_id: "",
//         serial_number: "",
//         description: "",
//         user: userId,
//       });
//       setError("");
//     } catch (error) {
//       setError(error.message);
//       console.error("Error saving device data:", error.message);
//     }
//   };





  
//   return (
//     <ThemeProvider theme={theme}>
//       <Container component={Paper} sx={{ mt: 4, p: 4 }}>
//         <Typography variant="h5" component="h1" gutterBottom>
//           <DevicesIcon fontSize="large" sx={{ mr: 1 }} />
//           Add CIM10
//         </Typography>
//         <form onSubmit={handleSubmit}>
//           <TextField
//             variant="standard"
//             label="Device Name"
//             name="device_name"
//             value={deviceData.device_name}
//             onChange={handleChange}
//             fullWidth
//             required
//             margin="normal"
//           />
//           <TextField
//             variant="standard"
//             label="IP Address"
//             name="ip_address"
//             value={deviceData.ip_address}
//             onChange={handleChange}
//             fullWidth
//             required
//             margin="normal"
//           />
//           <TextField
//             variant="standard"
//             label="MAC ID"
//             name="mac_id"
//             value={deviceData.mac_id}
//             onChange={handleChange}
//             fullWidth
//             required
//             margin="normal"
//           />
//           <TextField
//             variant="standard"
//             label="Serial Number"
//             name="serial_number"
//             value={deviceData.serial_number}
//             onChange={handleChange}
//             fullWidth
//             required
//             margin="normal"
//           />
//           <TextField
//             variant="standard"
//             label="Description"
//             name="description"
//             value={deviceData.description}
//             onChange={handleChange}
//             fullWidth
//             multiline
//             rows={4}
//             margin="normal"
//           />
//           <Box mt={2} textAlign="right">
//             <Button
//               variant="contained"
//               color="primary"
//               type="submit"
//               startIcon={<SaveIcon />}
//             >
//               Save
//             </Button>
//           </Box>
//           {error && <Typography color="error">{error}</Typography>}
//         </form>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default DeviceForm;



// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   Button,
//   Container,
//   Typography,
//   Paper,
//   Box,
//   Modal,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TableContainer,
// } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import SaveIcon from "@mui/icons-material/Save";
// import DevicesIcon from "@mui/icons-material/Devices";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#1976d2",
//     },
//     secondary: {
//       main: "#dc004e",
//     },
//   },
//   typography: {
//     h5: {
//       fontWeight: 600,
//     },
//   },
// });

// const DeviceForm = () => {


//   const userId = localStorage.getItem("userId")
//   const [deviceData, setDeviceData] = useState({
//     device_name: "",
//     ip_address: "",
//     mac_id: "",
//     serial_number: "",
//     description: "",
//     user: userId,
//   });
//   const [error, setError] = useState("");
//   const [devices, setDevices] = useState([]);
//   const [open, setOpen] = useState(false);

//   // Fetch devices user-wise on component load
//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/get/`);
//         const data = await response.json();
//         setDevices(data);
//       } catch (error) {
//         console.error("Error fetching devices:", error);
//       }
//     };
//     fetchDevices();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setDeviceData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/create/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(deviceData),
//       });
//       if (!response.ok) {
//         const errorData = await response.json();
//         console.log("Error response data:", errorData);
//         throw new Error("Failed to save device data");
//       }
//       const result = await response.json();
//       console.log("Device data saved:", result);

//       // Refresh the devices list after adding
//       setDevices([...devices, result]);

//       // Reset form and close modal
//       setDeviceData({
//         device_name: "",
//         ip_address: "",
//         mac_id: "",
//         serial_number: "",
//         description: "",
//         user: 1,
//       });
//       setOpen(false);
//       setError("");
//     } catch (error) {
//       setError(error.message);
//       console.error("Error saving device data:", error.message);
//     }
//   };

//   // Open/close modal handlers
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component={Paper} sx={{ mt: 4, p: 4 }}>
//         <Typography variant="h5" component="h1" gutterBottom>
//           <DevicesIcon fontSize="large" sx={{ mr: 1 }} />
//           CIM10 Devices
//         </Typography>

//         {/* Table displaying devices */}
//         <TableContainer component={Paper} sx={{ mb: 2 }}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Device Name</TableCell>
//                 <TableCell>IP Address</TableCell>
//                 <TableCell>MAC ID</TableCell>
//                 <TableCell>Serial Number</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {devices.map((device) => (
//                 <TableRow key={device.device_id}>
//                   <TableCell>{device.device_name}</TableCell>
//                   <TableCell>{device.ip_address}</TableCell>
//                   <TableCell>{device.mac_id}</TableCell>
//                   <TableCell>{device.serial_number}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         {/* Button to open the form modal */}
//         <Button variant="contained" color="primary" onClick={handleOpen}>
//           Add CIM10 Device
//         </Button>

//         {/* Modal for adding new device */}
//         <Modal open={open} onClose={handleClose}>
//           <Box
//             component={Paper}
//             sx={{
//               width: 400,
//               p: 4,
//               margin: "auto",
//               mt: 10,
//               outline: "none",
//             }}
//           >
//             <Typography variant="h6" gutterBottom>
//               Add New Device
//             </Typography>
//             <form onSubmit={handleSubmit}>
//               <TextField
//                 variant="standard"
//                 label="Device Name"
//                 name="device_name"
//                 value={deviceData.device_name}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 margin="normal"
//               />
//               <TextField
//                 variant="standard"
//                 label="IP Address"
//                 name="ip_address"
//                 value={deviceData.ip_address}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 margin="normal"
//               />
//               <TextField
//                 variant="standard"
//                 label="MAC ID"
//                 name="mac_id"
//                 value={deviceData.mac_id}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 margin="normal"
//               />
//               <TextField
//                 variant="standard"
//                 label="Serial Number"
//                 name="serial_number"
//                 value={deviceData.serial_number}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 margin="normal"
//               />
//               <TextField
//                 variant="standard"
//                 label="Description"
//                 name="description"
//                 value={deviceData.description}
//                 onChange={handleChange}
//                 fullWidth
//                 multiline
//                 rows={2}
//                 margin="normal"
//               />
//               <Box mt={2} textAlign="right">
//                 <Button variant="contained" color="primary" type="submit" startIcon={<SaveIcon />}>
//                   Save
//                 </Button>
//               </Box>
//               {error && <Typography color="error">{error}</Typography>}
//             </form>
//           </Box>
//         </Modal>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default DeviceForm;


import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Snackbar,
  Alert,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import DevicesIcon from "@mui/icons-material/Devices";
import AddIcon from "@mui/icons-material/Add";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
  },
});

const DeviceForm = ({ userId, onDeviceAdded }) => {
  // const userId = localStorage.getItem("userId")
  const [deviceData, setDeviceData] = useState({
    device_name: "",
    ip_address: "",
    mac_id: "",
    serial_number: "",
    description: "",
    user: userId,
  });
  const [error, setError] = useState("");
  const [devices, setDevices] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // For success message
  // const getAuthTokens = () => {
  //   const authTokensString = localStorage.getItem("authTokens");

  //   if (!authTokensString) {
  //     console.log("No authTokens found in localStorage");
  //     return null;
  //   }

  //   try {
  //     const authTokens = JSON.parse(authTokensString);

  //     if (authTokens && authTokens.access) {
  //       console.log("Access token:", authTokens.access);
  //       return authTokens.access;
  //     } else {
  //       console.log("Invalid authTokens format");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error parsing authTokens:", error);
  //     return null;
  //   }
  // };

  // const access = getAuthTokens();
  // console.log(access)

  // Fetch devices user-wise on component load
  const fetchDevices = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/get/${userId}/`,
        {
          headers: {
            // 'Authorization': `Bearer ${access}`,  // Include token here if using JWT
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch devices");
      }

      const data = await response.json();
      console.log("Devices:", data);
      setDevices(data);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  useEffect(() => {
    // Make sure `userId` is defined and valid // Replace this with actual userId
    fetchDevices(userId);
  }, [userId]);
  // fetchDevices(userId);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeviceData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/create/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(deviceData),
  //     });
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.log("Error response data:", errorData);
  //       throw new Error("Failed to save device data");
  //     }
  //     const result = await response.json();
  //     console.log("Device data saved:", result);

  //     // Refresh the devices list after adding
  //     // fetchDevices();

  //     // Show success message
  //     setSnackbarOpen(true);

  //     // Reset form and close modal
  //     setDeviceData({
  //       device_name: "",
  //       ip_address: "",
  //       mac_id: "",
  //       serial_number: "",
  //       description: "",
  //       user: userId,
  //     });
  //     setOpen(false);
  //     setError("");
  //     if (onDeviceAdded) {
  //       onDeviceAdded(userId);
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //     console.error("Error saving device data:", error.message);
  //   }
  // };

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Validation functions
  const validateDeviceName = (name) => {
    // Example validation for IoT gateway named CIM10 (Adjust as needed)
    return name.toLowerCase().includes("cim10");
  };

  const validateIpAddress = (ip) => {
    // IPv4 validation
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  };

  const validateMacId = (mac) => {
    // MAC ID should be exactly 12 characters long, alphanumeric with capital letters
    const macRegex = /^[A-Z0-9]{12}$/;
    return macRegex.test(mac);
  };

  const validateSerialNumber = (serial) => {
    // Serial Number should match the format "CIM10-A-J007"
    const serialRegex = /^CIM10-[A-Z]-[A-Z][0-9]{3}$/;
    return serialRegex.test(serial);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    if (!validateDeviceName(deviceData.device_name)) {
      setError("Invalid device name. Must be related to CIM10.");
      return;
    }
    if (!validateIpAddress(deviceData.ip_address)) {
      setError("Invalid IP address. Must be a valid IPv4 address.");
      return;
    }
    if (!validateMacId(deviceData.mac_id)) {
      setError("Invalid MAC ID. Must be exactly 12 alphanumeric characters.");
      return;
    }
    if (!validateSerialNumber(deviceData.serial_number)) {
      setError("Invalid Serial Number. Must follow the format CIM10-A-J007.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(deviceData),
        },
      );
  
      if (!response.ok) {
        const errorData = await response.json();
  
        // Check for specific error details and set the appropriate message
        if (errorData.serial_number?.[0]?.includes("already exists")) {
          setError("A device with this serial number already exists.");
        } else if (errorData.mac_id?.[0]?.includes("already exists")) {
          setError("A device with this MAC ID already exists.");
        } else if (errorData.ip_address?.[0]?.includes("already exists")) {
          setError("A device with this IP address already exists.");
        } else {
          setError("Failed to save device data.");
        }
        return;
      }
  
      const result = await response.json();
  
      setSnackbarOpen(true);
      setDeviceData({
        device_name: "",
        ip_address: "",
        mac_id: "",
        serial_number: "",
        description: "",
        user: userId,
      });
      setOpen(false);
      setError("");
      if (onDeviceAdded) {
        onDeviceAdded(userId);
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    }
  };
  // Open/close modal handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    // <ThemeProvider theme={theme}>
    <Container
      component={Paper}
      sx={{
        mt: 1,
        p: 1,
        boxShadow: "none",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      {/* Button to open the form modal */}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        startIcon={<AddIcon />}
        sx={{ fontWeight: "bold" }}
      >
        Add Device
      </Button>

      {/* Modal for adding new device */}
      <Modal open={open} onClose={handleClose}>
        <Box
          component={Paper}
          sx={{
            width: 400,
            p: 4,
            margin: "auto",
            mt: 10,
            outline: "none",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add New Device
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="standard"
              label="Device Name"
              name="device_name"
              value={deviceData.device_name}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              variant="standard"
              label="IP Address"
              name="ip_address"
              value={deviceData.ip_address}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              variant="standard"
              label="MAC ID"
              name="mac_id"
              value={deviceData.mac_id}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              variant="standard"
              label="Serial Number"
              name="serial_number"
              value={deviceData.serial_number}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              variant="standard"
              label="Description"
              name="description"
              value={deviceData.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              margin="normal"
            />
            <Box mt={2} textAlign="right">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Box>
            {error && <Typography color="error">{error}</Typography>}
          </form>
        </Box>
      </Modal>

      {/* Success Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Device added successfully!
        </Alert>
      </Snackbar>
    </Container>
    // {/* </ThemeProvider> */}
  );
};

export default DeviceForm;



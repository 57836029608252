// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { Container, Typography, Box, CircularProgress, Snackbar, Alert } from '@mui/material';

// const ApproveUserPage = () => {
//     const { userId } = useParams(); // Get user ID from URL
//     const [loading, setLoading] = useState(false);
//     const [success, setSuccess] = useState(false);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const approveUser = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/approve_user/${userId}/`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 if (response.ok) {
//                     setSuccess(true);
//                 } else {
//                     const errorData = await response.json();
//                     setError(errorData.error || 'An error occurred.');
//                 }
//             } catch (err) {
//                 setError('Network error occurred.');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         approveUser();
//     }, [userId]);

//     const handleCloseSnackbar = () => {
//         setSuccess(false);
//         setError(null);
//         setTimeout(() => {
//             window.close(); // Close the current tab or window
//         }, 1000); // Adjust the delay as needed
//     };

//     return (
//         <Container component="main" maxWidth="xs">
//             <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
//                 <Typography component="h1" variant="h5">
//                     Approving User
//                 </Typography>
//                 {loading ? (
//                     <CircularProgress sx={{ mt: 4 }} />
//                 ) : (
//                     <>
//                         {success && (
//                             <Snackbar
//                                 open={success}
//                                 autoHideDuration={2000} // Auto-hide after 2 seconds
//                                 onClose={handleCloseSnackbar}
//                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//                             >
//                                 <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
//                                     User account has been successfully approved!
//                                 </Alert>
//                             </Snackbar>
//                         )}
//                         {error && (
//                             <Snackbar
//                                 open={Boolean(error)}
//                                 autoHideDuration={6000}
//                                 onClose={handleCloseSnackbar}
//                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//                             >
//                                 <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
//                                     {error}
//                                 </Alert>
//                             </Snackbar>
//                         )}
//                     </>
//                 )}
//             </Box>
//         </Container>
//     );
// };

// export default ApproveUserPage;


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, Snackbar, Alert } from '@mui/material';

const ApproveUserPage = () => {
    const { token } = useParams(); // Get token from URL
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const approveUser = async () => {
            console.log('Approving user...'); // Debug statement
            console.log('Token:', token); // Debug: Check if token is captured correctly
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/approve_user/${token}/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                console.log('Response status:', response.status); // Debug: Check response status
                if (response.ok) {
                    console.log('User approved successfully.'); // Debug: Success case
                    setSuccess(true);
                } else {
                    const errorData = await response.json();
                    console.log('Error data:', errorData); // Debug: Log error data
                    setError(errorData.error || 'An error occurred.');
                }
            } catch (err) {
                console.log('Fetch error:', err); // Debug: Catch and log fetch errors
                setError('Network error occurred.');
            } finally {
                setLoading(false);
                console.log('Loading finished.'); // Debug: Loading state end
            }
        };

        if (token) { // Check if token is defined before calling the function
            approveUser();
        } else {
            console.log('Token is undefined.'); // Debug: Log if token is undefined
        }
    }, [token]);

    const handleCloseSnackbar = () => {
        setSuccess(false);
        setError(null);
        // setTimeout(() => {
        //     window.close(); // Close the current tab or window
        // }, 1000); // Adjust the delay as needed
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
                <Typography component="h1" variant="h5">
                    Approving User
                </Typography>
                {loading ? (
                    <CircularProgress sx={{ mt: 4 }} />
                ) : (
                    <>
                        {success && (
                            <Snackbar
                                open={success}
                                autoHideDuration={2000} // Auto-hide after 2 seconds
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                    User account has been successfully approved!
                                </Alert>
                            </Snackbar>
                        )}
                        {error && (
                            <Snackbar
                                open={Boolean(error)}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                                    {error}
                                </Alert>
                            </Snackbar>
                        )}
                    </>
                )}
            </Box>
        </Container>
    );
};

export default ApproveUserPage;


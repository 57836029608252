import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BeagleBoneIP = () => {
    const [ip, setIP] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/beaglebone/`)
            .then(response => {
                setIP(response.data.ip);
            })
            .catch(error => {
                setError('Failed to fetch IP');
                console.error(error);
            });
    }, []);

    return (
        <div>
            <h1>BeagleBone IP Address</h1>
            {ip ? <p>IP Address: {ip}</p> : <p>{error ? error : 'Fetching IP...'}</p>}
        </div>
    );
};

export default BeagleBoneIP;

// import React, { useEffect, useState } from 'react';
// import {
//   Modal,
//   Box,
//   Typography,
//   Button,
//   CircularProgress,
//   Alert,
// } from '@mui/material';

// const CodeDownloadModal = ({ open, onClose, userId }) => {
//   const [codeSnippet, setCodeSnippet] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const fetchCodeSnippet = async () => {
//     setLoading(true);
//     setError(null);
//     console.log('Fetching code snippet for user ID:', userId); // Debug statement

//     try {
//         const response = await fetch(`http://199.199.51.246:8000/api/user/user_apps/get/${userId}/`);
//         console.log('API Response Status:', response.status); // Debug statement

//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }

//         const data = await response.json();
//         console.log('Fetched data:', data); // Debug statement
//         setCodeSnippet(data.code_content); // Adjust this based on your API response structure

//     } catch (err) {
//         console.error('Error fetching code snippet:', err); // Debug statement
//         setError(err.message);
//     } finally {
//         setLoading(false);
//         console.log('Loading state reset'); // Debug statement
//     }
// };


// const handleDownload = () => {
//     const blob = new Blob([codeSnippet], { type: 'text/python' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = 'code_snippet.py'; // Filename for the downloaded file
//     link.click();
//     URL.revokeObjectURL(url); // Clean up the URL object
// };

// React.useEffect(() => {
//     if (open) {
//         fetchCodeSnippet(); // Fetch code snippet when modal opens
//     }
// }, [open]); // Depend on the 'open' state to fetch new data

// return (
//     <Modal open={open} onClose={onClose}>
//         <Box sx={{ ...style, width: 400 }}>
//             {loading && <CircularProgress />}
//             {error && <Alert severity="error">{error}</Alert>}
//             {!loading && !error && (
//                 <>
//                     <Typography variant="h6">Code Snippet</Typography>
//                     <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
//                         {codeSnippet}
//                     </Typography>
//                     <Button
//                         variant="outlined"
//                         color="primary"
//                         onClick={handleDownload}
//                         disabled={!codeSnippet}
//                         sx={{ marginTop: 2 }}
//                     >
//                         Download as .py
//                     </Button>
//                 </>
//             )}
//         </Box>
//     </Modal>
// );
// };

// // Style for the modal box
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   borderRadius: 2,
// };

// export default CodeDownloadModal;





// import React, { useState, useEffect } from 'react';
// import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton } from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import { format, parseISO } from 'date-fns';


// const styles = {
//     button: {
//       backgroundColor: '#1E88E5',
//       color: '#fff',
//       margin: '10px 0',
//       textTransform: 'none',
//       borderRadius: '4px',
//     },
//     modalPaper: {
//       margin: 'auto',
//       padding: '20px',
//       width: '80%',
//       marginTop: '5%',
//       backgroundColor: '#f4f6f8',
//       borderRadius: '8px',
//     },
//     tableContainer: {
//       maxHeight: '400px',
//       overflowY: 'auto',
//       border: '1px solid #e0e0e0',
//     },
//     iconButton: {
//       color: '#1E88E5',
//     },
//     codeModalPaper: {
//       margin: 'auto',
//       padding: '20px',
//       width: '60%',
//       marginTop: '5%',
//       backgroundColor: '#f4f6f8',
//       borderRadius: '8px',
//     },
//     codeContainer: {
//       maxHeight: '400px',
//       overflowY: 'scroll',
//       border: '1px solid #e0e0e0',
//       borderRadius: '4px',
//       padding: '10px',
//       backgroundColor: '#2d2d2d',
//     },
//   };
  
// const CodeDownloadModal = ({ open, onClose, userId }) => {
//   const [data, setData] = useState([]);
//   const [selectedCodeContent, setSelectedCodeContent] = useState('');
//   const [showCodePopup, setShowCodePopup] = useState(false);

//   // Fetch data from the API
//   useEffect(() => {
//     fetch(`http://199.199.51.246:8000/api/user/user_apps/get/${userId}/`)
//       .then(response => response.json())
//       .then(data => setData(data))
//       .catch(error => console.error("Error fetching data:", error));
//   }, [userId]);

//   // Open and close modal

//   const handleCloseCodePopup = () => setShowCodePopup(false);

//   // Show code content in a popup
//   const handleViewCode = (codeContent) => {
//     setSelectedCodeContent(codeContent);
//     setShowCodePopup(true);
//   };

//   // Download code content as a Python file
//   const handleDownload = (codeContent) => {
//     const element = document.createElement("a");
//     const file = new Blob([codeContent], { type: "text/plain" });
//     element.href = URL.createObjectURL(file);
//     element.download = "main.py";
//     document.body.appendChild(element);
//     element.click();
//   };

//   return (
//     <div>
//       {/* <Button variant="contained" onClick={handleOpen}>Show User Code Content</Button> */}
      
//       <Modal open={open} onClose={onClose}>
//         <Paper style={styles.modalPaper}>
//           <Typography variant="h6" align="center" gutterBottom>
//             User Apps
//           </Typography>

//           <TableContainer component={Paper} style={styles.tableContainer}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Serial Number</TableCell>
//                   <TableCell>Conversation Title</TableCell>
//                   {/* <TableCell>From ID</TableCell> */}
//                   <TableCell>Created At</TableCell>
//                   <TableCell>Actions</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((item, index) => (
//                   <TableRow key={item.id}>
//                     <TableCell>{index + 1}</TableCell>
//                     <TableCell>{item.conversation}</TableCell>
//                     {/* <TableCell>{item.user}</TableCell> */}
//                     <TableCell>
//   {item.createdAt ? format(parseISO(item.createdAt), 'MM/dd/yyyy') : "N/A"}
// </TableCell>

//                     <TableCell>
//                       <IconButton onClick={() => handleViewCode(item.code_content)} style={styles.iconButton}>
//                         <VisibilityIcon /> 
//                       </IconButton>
//                       <IconButton onClick={() => handleDownload(item.code_content)} style={styles.iconButton}>
//                         <DownloadIcon />
//                       </IconButton>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Paper>
//       </Modal>

//       {/* Popup to view code content with syntax highlighting */}
//       <Modal open={showCodePopup} onClose={handleCloseCodePopup}>
//         <Paper style={styles.codeModalPaper}>
//           <Typography variant="h6" align="center" gutterBottom>
//             Code 
//           </Typography>
//           <div style={styles.codeContainer}>
//             <SyntaxHighlighter language="python" style={materialDark} showLineNumbers>
//               {selectedCodeContent}
//             </SyntaxHighlighter>
//           </div>
//           <Button variant="contained" onClick={handleCloseCodePopup} style={styles.button}>
//             Close
//           </Button>
//         </Paper>
//       </Modal>

//     </div>
//   );
// };

// export default CodeDownloadModal;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Tooltip,  Dialog,
  DialogContent,
  DialogActions } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


import { format, parseISO } from 'date-fns';
import edgeSdkPyContent from './edgeSdkPy';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#C0EAEB", // ThingsBoard secondary color
    },
    // background: {
    //   default: '#F4F6F9', // Light background color
    // },
    // text: {
    //   primary: '#333333', // Dark text color
    // }
  },
});


const styles = {
    button: {
      backgroundColor: '#1E88E5',
      color: '#fff',
      margin: '10px 0',
      textTransform: 'none',
      borderRadius: '4px',
    },
    modalPaper: {
      margin: 'auto',
      padding: '20px',
      width: '80%',
      marginTop: '5%',
      backgroundColor: '#f4f6f8',
      borderRadius: '8px',
    },
    tableContainer: {
      maxHeight: '400px',
      overflowY: 'auto',
      border: '1px solid #e0e0e0',
    },
    iconButton: {
      color: '#1E88E5',
    },
    codeModalPaper: {
      margin: 'auto',
      padding: '20px',
      width: '60%',
      marginTop: '5%',
      backgroundColor: '#f4f6f8',
      borderRadius: '8px',
    },
    codeContainer: {
      maxHeight: '400px',
      overflowY: 'scroll',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      padding: '10px',
      backgroundColor: '#2d2d2d',
    },
  };

const CodeDownloadModal = ({ open, onClose, userId }) => {
  const [data, setData] = useState([]);
  const [selectedCodeContent, setSelectedCodeContent] = useState('');
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [socket, setSocket] = useState(null);

  // Establish WebSocket connection on component mount
  // useEffect(() => {
  //   const ws = new WebSocket(`ws://199.199.51.246:8000/ws/user_apps/${userId}/`);
  //   ws.onopen = () => console.log("WebSocket connection established.");
  //   ws.onmessage = (event) => {
  //     const updatedData = JSON.parse(event.data);
  //     setData(updatedData);
  //   };
  //   ws.onerror = (error) => console.error("WebSocket error:", error);
  //   ws.onclose = () => console.log("WebSocket connection closed.");

  //   setSocket(ws);

  //   // Cleanup on component unmount
  //   return () => {
  //     if (socket) {
  //       socket.close();
  //     }
  //   };
  // }, [userId]);


  useEffect(() => {
    console.log("Fetching user apps...");
    fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get/${userId}/`)
      .then(response => {
        console.log("Response received:", response);
        if (!response.ok) {
          throw new Error("Failed to fetch user apps");
        }
        return response.json();
      })
      .then(data => {
        console.log("Data fetched successfully:", data);
        setData(data);
      })
      .catch(error => console.error("Error fetching data:", error));
  }, [userId]);

  const handleCloseCodePopup = () => setShowCodePopup(false);

  const handleViewCode = (codeContent) => {
    setSelectedCodeContent(codeContent);
    setShowCodePopup(true);
  };

  const handleDownload = (content, filename, contentType = "text/plain") => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };

  const handleDownloadCode = (codeContent) => {
    handleDownload(codeContent, "main.py", "text/plain");
  };

  const handleDownloadConfig = (configData) => {
    const jsonContent = JSON.stringify(configData, null, 2);
    handleDownload(jsonContent, "config.json", "application/json");
  };

  const handleDownloadEdgeSdkPy = () => {
    handleDownload(edgeSdkPyContent, "edgeSdkPy.py", "text/plain");
  };

  const [showJsonPopup, setShowJsonPopup] = useState(false);
  const [selectedJsonContent, setSelectedJsonContent] = useState('');

  const handleViewJson = (configData) => {
    setSelectedJsonContent(JSON.stringify(configData, null, 2));
    setShowJsonPopup(true);
  };

  const handleCloseJsonPopup = () => setShowJsonPopup(false);

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Paper style={styles.modalPaper}>
          <Typography variant="h6" align="center" gutterBottom>
            User Apps
          </Typography>

          <Button
            variant="contained" 
            color="primary"
            onClick={handleDownloadEdgeSdkPy}
            startIcon={<DownloadIcon />}
            style={{
              margin: '8px',
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            EdgeSDK
          </Button>

          <TableContainer component={Paper} style={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial Number</TableCell>
                  <TableCell>Conversation Title</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.conversation}</TableCell>
                    <TableCell>
                      {item.createdAt ? format(parseISO(item.createdAt), 'MM/dd/yyyy') : "N/A"}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View Code">
                        <IconButton onClick={() => handleViewCode(item.code_content)} style={styles.iconButton}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download Code">
                        <IconButton onClick={() => handleDownloadCode(item.code_content)} style={styles.iconButton}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Config">
                        <IconButton onClick={() => handleViewJson(item.config_data)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download Config">
                        <IconButton onClick={() => handleDownloadConfig(item.config_data)} style={styles.iconButton}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>

      <Dialog open={showJsonPopup} onClose={handleCloseJsonPopup} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="h6">Configuration JSON</Typography>
          {/* <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {selectedJsonContent}
          </pre> */}
          <SyntaxHighlighter language="json" style={materialDark} showLineNumbers>
              {selectedJsonContent}
            </SyntaxHighlighter>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJsonPopup} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Modal open={showCodePopup} onClose={handleCloseCodePopup}>
        <Paper style={styles.codeModalPaper}>
          <Typography variant="h6" align="center" gutterBottom>
            Code 
          </Typography>
          <div style={styles.codeContainer}>
            <SyntaxHighlighter language="python" style={materialDark} showLineNumbers wrapLines>
              {selectedCodeContent}
            </SyntaxHighlighter>
          </div>
          <Button variant="contained" onClick={handleCloseCodePopup} style={styles.button}>
            Close
          </Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default CodeDownloadModal;


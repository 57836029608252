// const CIMCOPILOT_WELCOME_MESSAGE = `Hello there! I'm CIMCopilot, your intelligent digital assistant here to make your life easier when setting up your CIM10 IoT Edge gateway. Using cutting-edge AI, ML, and NLP technologies, I'm here to guide you through every step of the process, no matter your technical background.\n\n
// **Setup Made Easy:**
// With my intuitive chat interface, I can understand your questions and provide clear answers. Need help with physical device connections? No problem, I've got you covered. I can even generate and help you download the configuration files you need.\n\n
// **Seamless Configuration:**
// Uploading configurations to your CIM10 devices? Piece of cake! I'll walk you through the process step by step, ensuring everything runs smoothly.\n\n
// **Continuous Improvement:**
// I'm not just a one-time helper. I'm always learning from your interactions to provide even better assistance in the future. Think of me as your personal IoT guru, dedicated to enhancing your experience with the CIM10 gateway.\n\n
// **User-Centric Support:**
// My goal is to minimize downtime and maximize your satisfaction. With CIMCopilot by your side, managing your IoT devices has never been more seamless.\n\n
// `;

const CIMCOPILOT_WELCOME_MESSAGE = `Hi! I'm CIM Copilot, your assistant for setting up your CIM10 IoT Edge Gateway. Whether you're new or experienced, I use AI and NLP to guide you through the process.\n\n
**Easy Setup:**
I can quickly understand your questions and help you with device connections or generate and download configuration files.\n\n
**Smooth Configuration:**
I’ll walk you through uploading configurations step-by-step.\n\n
**Always Improving:**
I learn from interactions to get better at helping you. Think of me as your go-to guide for managing CIM10 devices.\n\n
**User-Centric Support:**
My goal is to reduce downtime and make your experience as easy as possible.\n\n
`;



export default CIMCOPILOT_WELCOME_MESSAGE;

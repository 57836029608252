import React, { useState, useEffect } from "react";
import {
  // Button,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Modal,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
  Tooltip,
  Grid,
  Avatar,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import EmailModal from "../emailPage/EmailModal";
import CredentialsModal from "../credentialsPage/CredentialsModal";
import ProfileModal from "./profileModal";
import OpenInfoModal from "./openInfoModal";
import "./userProfile.css";
import ErrorBoundary from "./errorBoundary";
import { grey } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC",
    },
    secondary: {
      main: "#C0EAEB",
    },
    background: {
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
    grey: {
      500: "#9e9e9e",
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
      color: "#333333",
    },
    body1: {
      color: "#666666",
    },
  },
});

const UserProfileDropdown = ({}) => {
  const [openProfile, setOpenProfile] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState("");
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openCredentialsModal, setOpenCredentialsModal] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  //   const { logout } = useAuth();
  const { logout } = useAuth();

  const clearSessionStorage = () => {
    sessionStorage.removeItem("messages");
    sessionStorage.removeItem("welcomeDisplayed");
    sessionStorage.removeItem("showFeedback");
    sessionStorage.removeItem("conversations");
    sessionStorage.removeItem("isLoading");
    sessionStorage.removeItem("conversationId");
    sessionStorage.removeItem("uploadedFile")
    sessionStorage.removeItem("selectedDevice")
  };
  // const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  // console.log("auth: ", authTokens)
  // const accessToken = authTokens?.access;
  // console.log("Access Token: ", accessToken);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/api/users/${userId}/`,
          {
            headers: {
              // 'X-Authorization': `Bearer ${accessToken}`
            },
          },
        );
        console.log("Response Headers:", response.headers);
        console.log("Response Data:", response.data);
        if (response.status === 200) {
          setUser(response.data);

          localStorage.setItem("username", response.data.name);
          localStorage.setItem("email", response.data.email)
          localStorage.setItem("companyname", response.data.company_name)
          localStorage.setItem("designation", response.data.designation)
          localStorage.setItem("mobilenumber", response.data.mobile_number)
        } else {
          throw new Error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      }
    };

    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  const updateUser = async (userDetails) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/users/update/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(userDetails),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user details');
      }
  
      const data = await response.json();
      console.log('User details updated successfully:', data);
      // Update local state or perform other actions as needed
    } catch (error) {
      console.error('Error updating user details:', error.message);
    }
  };


  const handleUpdateUser = async (updatedUser) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/users/update/${userId}/`,
        updatedUser,
        {
          headers: {
            // 'X-Authorization': `Bearer ${accessToken}`
          },
        },
      );
      if (response.status === 200) {
        setUser(response.data);
        localStorage.setItem("username", response.data.name);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("companyname", response.data.company_name);
        localStorage.setItem("designation", response.data.designation);
        localStorage.setItem("mobilenumber", response.data.mobile_number);
        // Display success message using Snackbar or similar
      } else {
        throw new Error("Failed to update user details");
      }
    } catch (error) {
      console.error("Error updating user details:", error.message);
      // Display error message using Snackbar or similar
    }
  };



  const handleOpenProfile = () => {
    setOpenProfile(true);
    handleCloseMenu();
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };


  //   const handleLogout = () => {
  //     onLogout();
  //     handleCloseMenu();
  //   };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      // Make a GET request to logout endpoint
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/logout/`,
      );
      console.log("Logout Response:", response.data); // Log the response data

      // Remove token from localStorage
      localStorage.removeItem("authToken");
      logout(response.data);
      // Redirect to login page
      clearSessionStorage();
      navigate("/login");
    } catch (error) {
      console.error(
        "Error logging out:",
        error.response?.data || error.message,
      );
      // Optionally handle error display or retry logic
    }
  };

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };


  const handleOpenEmailModal = () => {
    setOpenEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
  };


  const sendEmail = async (emailDetails) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/send_email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailDetails),
      });
      const data = await response.json();
      if (response.ok) {
        alert('Email sent successfully!');
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleSendEmail = (emailContent, ) => {
    const { subject, body } = emailContent;
    const receiver = "support@cimcondigital.com";
 
    const emailDetails = {
      receiver,  // Update this to the intended receiver's email
      subject,
      body,
    };

    sendEmail(emailDetails);
    

    // window.location.href = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    handleCloseEmailModal();
  };

  const handleOpenCredentialsModal = () => {
    setOpenCredentialsModal(true);
  };

  const handleCloseCredentialsModal = () => {
    setOpenCredentialsModal(false);
  };

  const handleSaveCredentials = (credentials) => {
    // Your logic to save credentials into the database
    console.log('Saving credentials:', credentials);

    // Close the modal after saving credentials
    handleCloseCredentialsModal();
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Tooltip title="Profile">
          <IconButton
            aria-label="user-profile-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
            style={{ position: "fixed", top: "16px", right: "16px" }}
          >
            <PersonIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Information"
          arrow
          sx={{
            tooltip: {
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.background.paper,
              fontSize: theme.typography.fontSize,
              borderRadius: "4px",
              padding: "8px",
            },
            arrow: {
              color: theme.palette.text.primary,
            },
          }}
        >
          <IconButton
            aria-label="info"
            aria-haspopup="true"
            onClick={handleOpenInfo}
            color="inherit"
            style={{ position: "fixed", top: "16px", right: "50px" }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Raise Query"
          arrow
          sx={{
            tooltip: {
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.background.paper,
              fontSize: theme.typography.fontSize,
              borderRadius: "4px",
              padding: "8px",
            },
            arrow: {
              color: theme.palette.text.primary,
            },
          }}
        >
          <IconButton
            aria-label="info"
            aria-haspopup="true"
            onClick={handleOpenEmailModal}
            color="inherit"
            style={{ position: "fixed", top: "16px", right: "90px" }}
          >
            <img alt="support_icon"src="headphone.png" style={{height:24, width:26}}/>
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Add Cloud Credentials"
          arrow
          sx={{
            tooltip: {
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.background.paper,
              fontSize: theme.typography.fontSize,
              borderRadius: "4px",
              padding: "8px",
            },
            arrow: {
              color: theme.palette.text.primary,
            },
          }}
        >
          <IconButton
            aria-label="info"
            aria-haspopup="true"
            onClick={handleOpenCredentialsModal}
            color="inherit"
            style={{ position: "fixed", top: "16px", right: "130px" }}
          >
            <VpnKeyIcon/>
          </IconButton>
        </Tooltip>
        <CredentialsModal
        open={openCredentialsModal}
        handleClose={handleCloseCredentialsModal}
        handleSaveCredentials={handleSaveCredentials}
        theme={theme}
      />
        <EmailModal
        open={openEmailModal}
        handleClose={handleCloseEmailModal}
        handleSendEmail={handleSendEmail}
        theme={theme}
      />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 4,
            sx: {
              borderRadius: "8px",
              border: `1px solid ${theme.palette.divider}`,
              boxShadow: theme.shadows[4],
              minWidth: "200px", // Increase minWidth
              padding: "5px", // Add padding for more space
            },
          }}
        >
          <MenuItem
            onClick={handleOpenProfile}
            sx={{
              padding: "12px 16px", // Increase padding for MenuItem
              // fontSize: "1rem", // Increase font size
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
                // color: theme.palette.primary.contrastText,
                borderRadius: 2,
              },
              "&:active": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AccountBoxIcon sx={{ paddingRight: 1 }} />
              <Typography variant="inherit">My Profile</Typography>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            sx={{
              padding: "12px 16px", // Increase padding for MenuItem
              fontSize: "1rem", // Increase font size
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
                // color: theme.palette.primary.contrastText,
                borderRadius: 2,
              },
              "&:active": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LogoutIcon sx={{ paddingRight: 1 }} />
              <Typography variant="inherit">Logout</Typography>
            </Box>
          </MenuItem>
        </Menu>
        {/* <ErrorBoundary> */}

        {/* <ProfileModal
          open={openProfile}
          handleClose={handleCloseProfile}
          theme={theme}
          user={user}
          // updateUser = {updateUser}
        /> */}
        <ProfileModal
        open={openProfile}
        handleClose={handleCloseProfile}
        theme={theme}
        user={user}
        handleUpdateUser={handleUpdateUser}
      />
        {/* </ErrorBoundary> */}
   

        <OpenInfoModal open={openInfo} onClose={handleCloseInfo} theme={theme} />
      </div>
    </ThemeProvider>
  );
};

export default UserProfileDropdown;




import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import LockIcon from "@mui/icons-material/Lock";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./SignUpPage.css";
import copilot from "../../assets/images/copilot.png";
import VerifyOTPPage from "../otpVerification/verifyOtp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC",
    },
    secondary: {
      main: "#ff4081",
    },
  },
  typography: {
    h2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      color: "#333",
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600,
    },
  },
});

const SignUpPage = () => {
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/signup/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          mobile_number: mobileNumber,
          email,
          company_name: companyName,
          designation,
          password,
          confirm_password: confirmPassword,
        }),
      });

      if (response.ok) {
        setSnackbarMessage("Signup successful! Now Please Verify your email.");
        setSnackbarOpen(true);
        localStorage.setItem("verificationEmail", email)
        setTimeout(() => navigate("/verifyOtp"), 3000);
      } else {
        const errorData = await response.json();
        console.error("Error signing up user:", errorData);
        alert("Error signing up user. Please try again.");
      }
    } catch (error) {
      console.error("Error signing up user:", error);
      alert("Error signing up user. Please try again.");
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  
  //   if (!validateForm()) {
  //     return;
  //   }
  
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/signup/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         name,
  //         mobile_number: mobileNumber,
  //         email,
  //         company_name: companyName,
  //         designation,
  //         password,
  //         confirm_password: confirmPassword,
  //       }),
  //     });
  
  //     if (response.ok) {
  //       // Show a browser notification or alert
  //       if (Notification.permission === "granted") {
  //         new Notification("Signup successful! Now Please Verify your email.");
  //       } else {
  //         alert("Signup successful! Now Please Verify your email.");
  //       }
  //       localStorage.setItem("verificationEmail", email)
  //       setTimeout(() => navigate("/verifyOtp"), 3000);
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Error signing up user:", errorData);
  //       alert("Error signing up user. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error signing up user:", error);
  //     alert("Error signing up user. Please try again.");
  //   }
  // };
  
  // // // Request permission for notifications
  // // useEffect(() => {
  // //   if (Notification.permission !== "granted") {
  // //     Notification.requestPermission();
  // //   }
  // // }, []);
  

  const validateForm = () => {
    if (
      !name ||
      !mobileNumber ||
      !email ||
      !companyName ||
      !designation ||
      !password ||
      !confirmPassword
    ) {
      alert("All fields are required");
      return false;
    }

    if (!/^\d{10}$/.test(mobileNumber)) {
      alert("Enter a valid 10-digit mobile number");
      return false;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Enter a valid email address");
      return false;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return false;
    }

    return true;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  return (
    <ThemeProvider theme={theme}>
      <Container className="signupmain" component="main" maxWidth="md">
        <Paper elevation={6} sx={{ padding: 4, marginTop: 8 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="login-outer"></div>

            <div className="logo">
              <img
                src={copilot}
                alt="logo"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  marginBottom: "16px",
                }}
              />
            </div>
            <Box
              className="newdesignlabel"
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    name="fullName"
                    autoComplete="family-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                      startAdornment: <PersonIcon position="start" />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="mobileNumber"
                    label="Mobile Number"
                    name="mobileNumber"
                    autoComplete="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    InputProps={{
                      startAdornment: <PhoneIcon position="start" />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      startAdornment: <EmailIcon position="start" />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="companyName"
                    label="Company Name"
                    name="companyName"
                    autoComplete="organization"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    InputProps={{
                      startAdornment: <BusinessIcon position="start" />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="designation"
                    label="Designation"
                    name="designation"
                    autoComplete="organization-title"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    InputProps={{
                      startAdornment: <WorkIcon position="start" />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: <LockIcon position="start" />,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      startAdornment: <LockIcon position="start" />,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                className="login-button"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Typography
                className="signuptext"
                variant="body2"
                align="center"
                sx={{ mt: 2 }}
              >
                Already have an account?
                <Button color="primary" onClick={handleLogin}>
                  Login
                </Button>
              </Typography>
              <Typography
                className="poweredby"
                variant="caption"
                display="block"
                gutterBottom
              >
                Powered by <b>CIMCON Digital</b>
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default SignUpPage;

import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, Paper, Alert } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import copilot from "../../assets/images/copilot.png";


const theme = createTheme({
  palette: {
    primary: {
      main: "#1aa1db", // ThingsBoard primary color
    },
    secondary: {
      main: "#c0eaeb", // ThingsBoard secondary color
    },
  },
});

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/forget_password/`,
        { email }
      );
      setMessage(response.data.message);
      setError("");
      // Redirect to the password reset page or show confirmation
      alert("OTP has been sent to your email. You can now reset your password.");
      navigate("/reset-password");
    } catch (error) {
      setMessage("");
      setError(error.response?.data?.error || error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ padding: 4, marginTop: 12 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {/* <Typography variant="h5">Email Verification</Typography>
          <Typography variant="h5">Please enter the OTP sent to your email</Typography> */}
            <Typography variant="h6">Forgot Password</Typography>
            <Box component="form" onSubmit={handleForgotPassword} sx={{ mt: 3 }}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{ startAdornment: <EmailIcon position="start" /> }}
              />
              <Button type="submit" variant="contained" sx={{ mt: 3, fontWeight: "bold", color: "white" }}>
                Send OTP
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPasswordPage;

const edgeSdkPyContent = `
import sys
import time
import subscriber
from ctypes import *

EdgeSdk = CDLL("/usr/lib/libSdkPy.so")  # Or full path to file

# Modbus RTU SDK AIP

def readModbusByAddress_py(devicename, queryNum, paramNum):
    EdgeSdk.readModbusAddress.argtypes = c_char_p, c_int, c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readModbusAddress(bytes(devicename, "utf-8"), queryNum, paramNum))
    return returndata.value.decode("utf-8")


def readModbusByName_py(devicename, paramName):
    EdgeSdk.readModbusName.argtypes = c_char_p, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readModbusName(bytes(devicename, "utf-8"), bytes(paramName, "utf-8")))
    return returndata.value.decode("utf-8")


def readModbusByAll_py(devicename, callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    EdgeSdk.readModbusAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readModbusAll(bytes(devicename, "utf-8"), callback))
    return returndata


def readModbusByChange_py(devicename, callback_):
    topicName = ""+devicename+"/#"
    subscriber.run(topicName, callback_)
"""
def writeModbusByAddress_py(devicename, slaveid, ip, address, datatype, dataValue):
    # Define argument types for the writeModbusAddress function
    EdgeSdk.writeModbusAddress.argtypes = [c_char_p, c_int, c_char_p, c_int, c_int, c_void_p]
    EdgeSdk.writeModbusAddress.restype = c_int  # Assuming this is the return type

    # Convert ip and devicename to bytes (C-style strings)
    devicename_bytes = bytes(devicename, "utf-8")
    ip_bytes = bytes(ip, "utf-8")  # Convert the IP address to bytes

    # Check the data type and assign the correct type for dataValue
    if isinstance(dataValue, str):
        returndata = EdgeSdk.writeModbusAddress(devicename_bytes, slaveid, ip_bytes, address, datatype, dataValue)
    else:
        # Map datatype to corresponding C types
        if datatype == 1:
            datapointer = c_uint8(dataValue)
        elif datatype == 4:
            datapointer = c_int16(dataValue)
        elif datatype == 5:
            datapointer = c_uint16(dataValue)
        elif datatype == 6:
            datapointer = c_int32(dataValue)
        elif datatype == 7:
            datapointer = c_uint32(dataValue)
        elif datatype == 10 or datatype == 13:
            datapointer = c_float(dataValue)
        else:
            raise ValueError(f"Unsupported datatype: {datatype}")

        # Call the EdgeSdk.writeModbusAddress function
        returndata = EdgeSdk.writeModbusAddress(devicename_bytes, slaveid, ip_bytes, address, datatype, byref(datapointer))

    return returndata
"""
def writeModbusByAddress_py(devicename, slaveid, ip, address, datatype, dataValue):
    EdgeSdk.writeModbusAddress.argtypes = c_char_p, c_int, c_int, c_int, c_int,
    EdgeSdk.restype = None
    if isinstance(dataValue, str):
        returndata = c_int(EdgeSdk.writeModbusAddress(bytes(devicename, "utf-8"), slaveid, ip, address,  datatype, dataValue))
    else:
        if datatype == 1:
            datapointer = c_uint8(dataValue)
        elif datatype == 4:
            datapointer = c_int16(dataValue)
        elif datatype == 5:
            datapointer = c_uint16(dataValue)
        elif datatype == 6:
            datapointer = c_int32(dataValue)
        elif datatype == 7:
            datapointer = c_uint32(dataValue)
        elif datatype == 10:
            datapointer = c_float(dataValue)
        elif datatype == 13:
            datapointer = c_float(dataValue)
        returndata = c_int(EdgeSdk.writeModbusAddress(bytes(devicename, "utf-8"), slaveid, ip, address, datatype, byref(datapointer)))
    return returndata

# OPC UA SDK API

def readOPCUAByAddress_py(devicename, id, idtype, identifier):
    EdgeSdk.readOPCUAAddress.argtypes = c_char_p, c_int, c_int, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readOPCUAAddress(bytes(devicename, "utf-8"), id, idtype, bytes(identifier, "utf-8")))
    return returndata.value.decode("utf-8")


def readOPCUAByName_py(devicename, identifier):
    EdgeSdk.readOPCUAName.argtypes = c_char_p, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readOPCUAName(bytes(devicename, "utf-8"), bytes(identifier, "utf-8")))
    return returndata.value.decode("utf-8")


def readOPCUAByAll_py(devicename, callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    EdgeSdk.readOPCUAAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readOPCUAAll(bytes(devicename, "utf-8"), callback))
    return returndata


def readOPCUAByChange_py(devicename, callback_):
    topicName = ""+devicename+"/#"
    subscriber.run(topicName, callback_)


def writeOPCUAByAddress_py(devicename, id, idtype, identifier, datatype, dataValue):
    EdgeSdk.writeOPCUAAddress.argtypes = c_char_p, c_int, c_int, c_char_p,
    EdgeSdk.restype = None
    if isinstance(dataValue, str):
        returndata = c_int(EdgeSdk.writeOPCUAAddress(bytes(devicename, "utf-8"), id, idtype, bytes(identifier, "utf-8"), datatype, dataValue))
    else:
        datapointer = c_double(dataValue)
        returndata = c_int(EdgeSdk.writeOPCUAAddress(bytes(devicename, "utf-8"), id, idtype, bytes(identifier, "utf-8"), datatype, byref(datapointer)))
    return returndata


def writeOPCUAByName_py(devicename, name, datatype, dataValue):
    EdgeSdk.writeOPCUAName.argtypes = c_char_p, c_char_p, c_int,
    EdgeSdk.restype = None
    if isinstance(dataValue, str):
        returndata = c_int(EdgeSdk.writeOPCUAName(bytes(devicename, "utf-8"), bytes(name, "utf-8"), datatype, dataValue))
    else:
        datapointer = c_double(dataValue)
        returndata = c_int(EdgeSdk.writeOPCUAName(bytes(devicename, "utf-8"), bytes(name, "utf-8"), datatype, byref(datapointer)))
    return returndata



# OPCDA API

def readOPCDAByAddress_py(devicename, address):
    EdgeSdk.readOPCDAAddress.argtypes = c_char_p, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readOPCDAAddress(bytes(devicename, "utf-8"), bytes(address, "utf-8")))
    return returndata.value.decode("utf-8")


def readOPCDAByName_py(devicename, name):
    EdgeSdk.readOPCDAName.argtypes = c_char_p, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readOPCDAName(bytes(devicename, "utf-8"), bytes(name, "utf-8")))
    return returndata.value.decode("utf-8")


def readOPCDAByAll_py(devicename, callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    EdgeSdk.readOPCDAAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readOPCDAAll(bytes(devicename, "utf-8"), callback))
    return returndata


def readOPCDAByChange_py(devicename, callback_):
    topicName = ""+devicename+"/#"
    subscriber.run(topicName, callback_)

# BLE API

def readBleByAddress_py(devicename, characteristic_id):
    EdgeSdk.readBleAddress.argtypes = c_char_p, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readBleAddress(bytes(devicename, "utf-8"), bytes(characteristic_id, "utf-8")))
    return returndata.value.decode("utf-8")


def readBleByName_py(devicename, characteristic_id, para_name):
    EdgeSdk.readBleName.argtypes = c_char_p, c_char_p, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readBleName(bytes(devicename, "utf-8"), bytes(characteristic_id, "utf-8"),bytes(para_name, "utf-8")))
    return returndata.value.decode("utf-8")

def readBleByChange_py(devicename, callback_):
    topicName = ""+devicename+"/#"
    subscriber.run(topicName, callback_)

def readBleByAll_py(devicename, callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    EdgeSdk.readBleAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readBleAll(bytes(devicename, "utf-8"), callback))
    return returndata

# BACnet Python API

def readBACnetByAddress_py(devicename, deviceid, objtype, instanceid):
    EdgeSdk.readBACnetAddress.argtypes = c_char_p, c_int, c_int, c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readBACnetAddress(bytes(devicename, "utf-8"), deviceid, objtype, instanceid))
    return returndata.value.decode("utf-8")


def readBACnetByName_py(devicename, deviceid, paramName):
    EdgeSdk.readBACnetName.argtypes = c_char_p, c_int, c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readBACnetName(bytes(devicename, "utf-8"), deviceid, bytes(paramName, "utf-8")))
    return returndata.value.decode("utf-8")


def readBACnetByAll_py(devicename, callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    EdgeSdk.readBACnetAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readBACnetAll(bytes(devicename, "utf-8"), callback))
    return returndata

def readBACnetByChange_py(devicename, callback_):
    topicName = ""+devicename+"/#"
    subscriber.run(topicName, callback_)

def writeOPCUAByAddress_py(devicename, deviceid, objtype, instanceid, datatype, dataValue):
    EdgeSdk.writeBACnetAddress.argtypes = c_char_p, c_int, c_int, c_int,
    EdgeSdk.restype = None
    if isinstance(dataValue, str):
        returndata = c_int(EdgeSdk.writeBACnetAddress(bytes(devicename, "utf-8"), deviceid, objtype, instanceid, datatype, dataValue))
    else:
        datapointer = c_double(dataValue)
        returndata = c_int(EdgeSdk.writeBACnetAddress(bytes(devicename, "utf-8"), deviceid, objtype, instanceid, datatype, byref(datapointer)))
    return returndata


def writeBACnetByName_py(devicename, deviceid, name, datatype, dataValue):
    EdgeSdk.writeBACnetName.argtypes = c_char_p, c_int, c_char_p, c_int,
    EdgeSdk.restype = None
    if isinstance(dataValue, str):
        returndata = c_int(EdgeSdk.writeBACnetName(bytes(devicename, "utf-8"), deviceid, bytes(name, "utf-8"), datatype, dataValue))
    else:
        datapointer = c_double(dataValue)
        returndata = c_int(EdgeSdk.writeBACnetName(bytes(devicename, "utf-8"), deviceid, bytes(name, "utf-8"), datatype, byref(datapointer)))
    return returndata


# AI Python API

def readAiByAddress_py(address):
    EdgeSdk.readAiAddress.argtypes = c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readAiAddress(address))
    return returndata.value.decode("utf-8")


def readAiByName_py(name):
    EdgeSdk.readAiName.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readAiName(bytes(name, "utf-8")))
    return returndata.value.decode("utf-8")


def readAiByAll_py(callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    #EdgeSdk.readBACnetAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readAiAll(callback))
    return returndata

def readAiByChange_py(pinname, callback_):
    topicName = ""+pinname+"/#"
    subscriber.run(topicName, callback_)



# AO Python API

def readAoByAddress_py(address):
    EdgeSdk.readAoAddress.argtypes = c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readAoAddress(address))
    return returndata.value.decode("utf-8")


def readAoByName_py(name):
    EdgeSdk.readAoName.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readAoName(bytes(name, "utf-8")))
    return returndata.value.decode("utf-8")


def readAoByAll_py(callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    #EdgeSdk.readBACnetAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readAoAll(callback))
    return returndata

def readAoByChange_py(pinname, callback_):
    topicName = ""+pinname+"/#"
    subscriber.run(topicName, callback_)

def writeAoByAddress_py(address, value):
    EdgeSdk.writeAoAddress.argtypes = c_int, c_ushort,
    EdgeSdk.restype = None

    returndata = c_int(EdgeSdk.writeAoAddress(address, value))
    return returndata

def writeAoByName_py(pinname, value):
    EdgeSdk.writeAoName.argtypes = c_char_p, c_ushort,
    EdgeSdk.restype = None

    returndata = c_int(EdgeSdk.writeAoName((bytes(pinname, "utf-8")), value))
    return returndata



# DI Python API

def readDiByAddress_py(address):
    EdgeSdk.readDiAddress.argtypes = c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readDiAddress(address))
    return returndata.value.decode("utf-8")


def readDiByName_py(name):
    EdgeSdk.readDiName.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readDiName(bytes(name, "utf-8")))
    return returndata.value.decode("utf-8")


def readDiByAll_py(callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    #EdgeSdk.readBACnetAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readDiByAll(callback))
    return returndata

def readDiByChange_py(pinname, callback_):
    topicName = ""+pinname+"/#"
    subscriber.run(topicName, callback_)


# DO Python API

def readDoByAddress_py(address):
    EdgeSdk.readDoAddress.argtypes = c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readDoAddress(address))
    return returndata.value.decode("utf-8")


def readDoByName_py(name):
    EdgeSdk.readDoName.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readDoName(bytes(name, "utf-8")))
    return returndata.value.decode("utf-8")


def readDoByAll_py(callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    #EdgeSdk.readBACnetAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readDoAll(callback))
    return returndata

def readDoByChange_py(pinname, callback_):
    topicName = ""+pinname+"/#"
    subscriber.run(topicName, callback_)

def writeDoByAddress_py(address, value):
    EdgeSdk.writeDoAddress.argtypes = c_int, c_int,
    EdgeSdk.restype = None

    returndata = c_int(EdgeSdk.writeDoAddress(address, value))
    return returndata

def writeDoByName_py(pinname, value):
    EdgeSdk.writeDoName.argtypes = c_char_p, c_int,
    EdgeSdk.restype = None

    returndata = c_int(EdgeSdk.writeDoName((bytes(pinname, "utf-8")), value))
    return returndata


# DI Cnt Python API

def readDiCntByAddress_py(address):
    EdgeSdk.readDiCntAddress.argtypes = c_int,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readDiCntAddress(address))
    return returndata.value.decode("utf-8")


def readDiCntByName_py(name):
    EdgeSdk.readDiCntName.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_char_p(EdgeSdk.readDiCntName(bytes(name, "utf-8")))
    return returndata.value.decode("utf-8")


def readDiCntByAll_py(callback_):
    callback = CFUNCTYPE(None, c_char_p)(callback_)
    #EdgeSdk.readBACnetAll.argtypes = c_char_p,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.readDiCntAll(callback))
    return returndata

def readDiCntByChange_py(pinname, callback_):
    topicName = ""+pinname+"/#"
    subscriber.run(topicName, callback_)



# Transport API

def SendToEndPoint_Py(devicename, payload, payloadLen):
    EdgeSdk.SendToEnd.argtypes = c_char_p, c_char_p, c_int,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.SendToEnd(bytes(devicename, "utf-8"), bytes(payload, "utf-8"), payloadLen))
    return returndata

def Sendto_py(devicename, payload, payloadLen):
    EdgeSdk.Sendto.argtypes = c_char_p, c_char_p, c_int,
    EdgeSdk.restype = None
    returndata = c_int(EdgeSdk.Sendto(bytes(devicename, "utf-8"), bytes(payload, "utf-8"), payloadLen))
    return returndata

def ReceiveFrom_Py(devicename, callback_):
    topicName = ""+devicename+"/#"
    subscriber.run(topicName, callback_)

  `;

export default edgeSdkPyContent;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Grid
} from "@mui/material";
// import ApprovalPending from "../ApprovalPending/ApprovalPending";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#ff4081", // ThingsBoard secondary color
    },
    background: {
      default: "#F5F5F5", // Light background color for the page
    },
  },
  typography: {
    h5: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600,
      color: "#333",
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600,
      textTransform: "none",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 20,
          borderRadius: 8,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 20px",
          borderRadius: 4,
        },
      },
    },
  },
});

const VerifyOTPPage = () => {
//   const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
//   const history = useHistory();
  const email = localStorage.getItem("verificationEmail");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/verify_otp/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });

      setLoading(false);

      if (response.ok) {
        // setSnackbarMessage("OTP verified successfully. Please wait for account approval.");
        setSnackbarMessage("OTP verified successfully.");
        setSnackbarOpen(true);
        // history.push('/approval-pending');
        // setTimeout(() => navigate("/login"), 3000);
        setTimeout(() => navigate("/approvalPending"), 3000);
      } else {
        const errorData = await response.json();
        console.error("Error verifying OTP:", errorData);
        setSnackbarMessage("Invalid OTP or OTP expired.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error verifying OTP:", error);
      setSnackbarMessage("Error verifying OTP. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
        <Paper elevation={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Verify Your Email
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%", mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="otp"
                    label="OTP"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, width: "100%" }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Verify OTP"}
              </Button>
            </Box>
          </Box>
        </Paper>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarMessage.includes("success") ? "success" : "error"}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default VerifyOTPPage;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Typography, Paper, List, ListItem, ListItemText, Divider, CircularProgress } from '@mui/material';
// import { useParams } from 'react-router-dom';
// import UserQuestion from '../chatPage/useQuestion';
// import ChatbotResponse from '../chatPage/chatbotResponse';

// const OpenSearchedChat = () => {
//     const { id } = useParams(); // Either conversationId or messageId
//     const [chatData, setChatData] = useState({ conversation: {}, messages: [] });
//     // const [chatData, setChatData] = useState({ conversation: {}});
//     // const [chatData, setChatData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchChatData = async () => {
//             try {
//                 setLoading(true);
//                 setError(null);
                
//                 // if (id.startsWith('message')) {
//                 if (id){
                    
//                     // const messageId = id.split('/')[2];
//                     const messageResponse = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/get_chat_history_by_conv_id/${id}/`);
//                     console.log("message response", JSON.stringify(messageResponse.data))
//                     // console.log("cov respose: ", messageResponse.data.conversation)
//                     // console.log("messages: ", messageResponse.data.messages)
//                     // console.log("message response ", setChatData)
//                     // setChatData({ conversation: messageResponse.data.conversation});
//                     setChatData({ conversation: messageResponse.data, messages: messageResponse.data.messages });
//                     // setChatData({ conversation: messageResponse.data.conversation, messages: messageResponse.data.messages });

//                     // setChatData(messageResponse.data)
//                     // console.log("message response ", setChatData)

//                     // setChatData({ messages: messageResponse.data.messages });

//                 } 
//                 // else {
//                 //     const conversationResponse = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/chat-history/${id}/`);
//                 //     console.log("con data 2: ", conversationResponse.data)
//                 //     setChatData(conversationResponse.data);
//                 // }
//             } catch (error) {
//                 setError('Error fetching chat data.');
//                 console.error('Error fetching chat data:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchChatData();
//     }, [id]);

//     if (loading) {
//         return <CircularProgress />;
//     }

//     if (error) {
//         return <Typography color="error">{error}</Typography>;
//     }

//     return (
//         <Paper style={{ padding: '16px', margin: '16px' }}>
//             {chatData.conversation && chatData.conversation.title ? (
//                 <>
//                     <Typography variant="h4">{chatData.conversation.title}</Typography>
//                     <Divider />
//                     <List>
//                         {chatData.messages.length > 0 ? (
//                             chatData.messages.map((message) => (
                                
//                                 <ListItem key={message.id}>
//                                     <UserQuestion text={message.question}/>
//                                     {/* <ListItemText
//                                         primary={<Typography variant="subtitle1">Question:</Typography>}
//                                         secondary={message.question}
//                                     />
//                                     <ListItemText
//                                         primary={<Typography variant="subtitle1">Answer:</Typography>}
//                                         secondary={message.answer}
//                                     /> */}
//                                     <ChatbotResponse
//                                         text={message.answer}
//                                         enableTextEffect={false}
//                                         />
//                                 </ListItem>
//                             ))
//                         ) : (
//                             <Typography>No messages found.</Typography>
//                         )}
//                     </List>
//                 </>
//             ) : (
//                 <Typography>No conversation data available.</Typography>
//             )}
//         </Paper>
//     );
// };

// export default OpenSearchedChat;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Typography, Paper, List, ListItem, Divider, CircularProgress, Box } from '@mui/material';
// import { useParams } from 'react-router-dom';
// import UserQuestion from '../chatPage/useQuestion';
// import ChatbotResponse from '../chatPage/chatbotResponse';

// const OpenSearchedChat = () => {
//     const { id } = useParams(); // Either conversationId or messageId
//     const [chatData, setChatData] = useState({ conversation: {}, messages: [] });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchChatData = async () => {
//             try {
//                 setLoading(true);
//                 setError(null);

//                 if (id) {
//                     const messageResponse = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/get_chat_history_by_conv_id/${id}/`);
//                     setChatData({ conversation: messageResponse.data, messages: messageResponse.data.messages });
//                 }
//             } catch (error) {
//                 setError('Error fetching chat data.');
//                 console.error('Error fetching chat data:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchChatData();
//     }, [id]);

//     if (loading) {
//         return (
//             <Box display="flex" justifyContent="center" alignItems="center" height="100%">
//                 <CircularProgress />
//             </Box>
//         );
//     }

//     if (error) {
//         return <Typography color="error">{error}</Typography>;
//     }

//     return (
//         <Paper style={{ padding: '24px', margin: '24px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
//             {chatData.conversation && chatData.conversation.title ? (
//                 <>
//                     <Typography variant="h5" style={{ marginBottom: '16px', fontWeight: 'bold', color: '#3f51b5' }}>
//                         {chatData.conversation.title}
//                     </Typography>
//                     <Divider style={{ marginBottom: '16px' }} />
//                     <List>
//                         {chatData.messages.length > 0 ? (
//                             chatData.messages.map((message) => (
//                                 <ListItem key={message.id} style={{ marginBottom: '16px', backgroundColor: '#ffffff', borderRadius: '8px', padding: '16px' }}>
//                                     <Box>
//                                         <UserQuestion text={message.question} />
//                                         <Divider style={{ margin: '16px 0' }} />
//                                         <ChatbotResponse text={message.answer} enableTextEffect={false} />
//                                     </Box>
//                                 </ListItem>
//                             ))
//                         ) : (
//                             <Typography>No messages found.</Typography>
//                         )}
//                     </List>
//                 </>
//             ) : (
//                 <Typography>No conversation data available.</Typography>
//             )}
//         </Paper>
//     );
// };

// export default OpenSearchedChat;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Paper, List, ListItem, Divider, CircularProgress, Box, Card, CardContent, CardHeader, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import UserQuestion from '../chatPage/useQuestion';
import ChatbotResponse from '../chatPage/chatbotResponse';


const OpenSearchedChat = () => {
    const { id } = useParams(); // Either conversationId or messageId
    const [chatData, setChatData] = useState({ conversation: {}, messages: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchChatData = async () => {
            try {
                setLoading(true);
                setError(null);

                if (id) {
                    const messageResponse = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/get_chat_history_by_conv_id/${id}/`);
                    setChatData({ conversation: messageResponse.data, messages: messageResponse.data.messages });
                }
            } catch (error) {
                setError('Error fetching chat data.');
                console.error('Error fetching chat data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchChatData();
    }, [id]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={60} />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    const formatDateTime = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const formattedDate = chatData.conversation.created_at ? formatDateTime(chatData.conversation.created_at) : '';

    return (
        <Paper
            elevation={3} // Adding subtle shadow
            style={{
                padding: '32px',
                margin: '32px auto',
                backgroundColor: '#f7f9fc', // Light background color
                borderRadius: '16px', // Softer rounded corners
                maxWidth: '800px',
                width: '100%',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            }}
        >
            {chatData.conversation && chatData.conversation.title ? (
                <>
                    <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#2c3e50' }}>
                        {chatData.conversation.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {formattedDate}
                    </Typography>
                    <Divider style={{ marginBottom: '32px' }} />
                    <List>
                        {chatData.messages.length > 0 ? (
                            chatData.messages.map((message) => (
                                <ListItem key={message.id} style={{ paddingBottom: '24px' }}>
                                    <Card
                                        elevation={1} // Slight shadow to cards
                                        style={{
                                            width: '100%',
                                            borderRadius: '12px',
                                            backgroundColor: '#ffffff',
                                            padding: '16px',
                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)', // Soft shadow for cards
                                        }}
                                    >
                                        <CardHeader
                                            title={<UserQuestion text={message.question} />}
                                            style={{
                                                paddingBottom: '0',
                                                backgroundColor: '#e3f2fd', // Subtle blue background for user questions
                                                borderRadius: '8px',
                                                padding: '12px',
                                                marginBottom: '8px'
                                            }}
                                        />
                                        <CardContent
                                            style={{
                                                paddingTop: '0',
                                                backgroundColor: '#f5f5f5', // Slightly different background for chatbot responses
                                                borderRadius: '8px',
                                                padding: '16px',
                                            }}
                                        >
                                            <ChatbotResponse text={message.answer} enableTextEffect={false} />
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            ))
                        ) : (
                            <Typography variant="body1" color="textSecondary">
                                No messages found.
                            </Typography>
                        )}
                    </List>
                </>
            ) : (
                <Typography variant="body1" color="textSecondary">
                    No conversation data available.
                </Typography>
            )}
        </Paper>
    );
};

export default OpenSearchedChat;

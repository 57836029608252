// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { TextField, Typography, Paper, List, ListItem, ListItemText, Divider, InputAdornment, Collapse } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import Highlighter from "react-highlight-words";
// import UserQuestion from '../chatPage/useQuestion';
// import ChatbotResponse from '../chatPage/chatbotResponse';
// import SearchIcon from '@mui/icons-material/Search'; // Import the Search icon
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// const MasterSearch = () => {
//     const [query, setQuery] = useState('');
//     const [results, setResults] = useState({ conversations: [], messages: [] });
//     const [expandedConversations, setExpandedConversations] = useState(true);
//     const [expandedMessages, setExpandedMessages] = useState(true);
//     const navigate = useNavigate();
//     const userId = localStorage.getItem("userId");

//     // function debounce(func, delay) {
//     //     let timer;
//     //     return function(...args) {
//     //         clearTimeout(timer);
//     //         timer = setTimeout(100,() => {
//     //             func.apply(this, args);
//     //         }, delay);
//     //     };
//     // }

//     useEffect(() => {
//         if (query.length > 0) {
//             axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/search/${userId}/?q=${query}`)
//                 .then(response => {
//                     setResults(response.data);
//                     console.log("search results: ", response.data);
//                 })
//                 .catch(error => console.error('Error fetching search results:', error));
//         } else {
//             setResults({ conversations: [], messages: [] });
//         }
//     }, [query]);


//     // const handleSearch = debounce((searchQuery) => {
//     //     axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/search/${userId}/?q=${searchQuery}`)
//     //         .then(response => {
//     //             setResults(response.data);
//     //             console.log("search results: ", response.data);
//     //         })
//     //         .catch(error => console.error('Error fetching search results:', error));
//     // }, 500);

//     // const handleChange = (e) => {
//     //     setQuery(e.target.value);
//     //     handleSearch(e.target.value);
//     // };

//     const handleItemClick = (type, id, conversationId = null) => {
//         if (type === 'conversation') {
//             // Navigate to the conversation thread
//             window.open(`/chat/${id}`, '_blank');
//         } else if (type === 'message' && conversationId) {
//             // Navigate to the corresponding conversation thread
//             window.open(`/chat/${conversationId}`, '_blank');
//         }
//     };

//     const handleToggleConversations = () => {
//         setExpandedConversations(prev => !prev);
//     };

//     const handleToggleMessages = () => {
//         setExpandedMessages(prev => !prev);
//     };

//     return (
//         <Paper sx={{ 
//             padding: '24px',
//             // paddingTop: "1px",
//             // top: '0px',
//             // margin: '24px',
//             backgroundColor: '#f9f9f9',
//             borderRadius: '8px',
//             boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
//             transition: "all 0.3s ease", // Smooth transition
//         }}>
//             <TextField
//                 fullWidth
//                 variant="outlined"
//                 value={query}
//                 onChange={(e) => setQuery(e.target.value)}
//                 placeholder="Search..."
//                 margin="normal"
//                 InputProps={{
//                     startAdornment: (
//                         <InputAdornment position="start">
//                             <SearchIcon color="action" />
//                         </InputAdornment>
//                     ),
//                 }}
//                 sx={{ 
//                     backgroundColor: '#ffffff',
//                     transition: "background-color 0.3s ease", // Smooth transition
//                 }}
//             />
//             <div style={{ marginTop: '16px' }}>
//                 <Typography 
//                     variant="h6" 
//                     style={{ fontWeight: 'bold', marginBottom: '8px' }}
//                     onClick={handleToggleConversations} 
//                     sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
//                 >
//                     Conversations
//                     {expandedConversations ? <ExpandLessIcon sx={{ ml: 1 }} /> : <ExpandMoreIcon sx={{ ml: 1 }} />}
//                 </Typography>
//                 <Collapse in={expandedConversations}>
//                     <List>
//                         {results.conversations.map((conversation) => (
//                             <ListItem
//                                 button
//                                 key={conversation.id}
//                                 onClick={() => handleItemClick('conversation', conversation.id)}
//                                 sx={{ padding: '12px 16px', borderRadius: '4px', '&:hover': { backgroundColor: '#f0f0f0' } }}
//                             >
//                                 <ListItemText
//                                     primary={
//                                         <Highlighter
//                                             searchWords={[query]}
//                                             autoEscape={true}
//                                             textToHighlight={conversation.title}
//                                         />
//                                     }
//                                 />
//                             </ListItem>
//                         ))}
//                     </List>
//                 </Collapse>
//             </div>
//             <Divider style={{ margin: '16px 0' }} />
//             <div>
//                 <Typography 
//                     variant="h6" 
//                     style={{ fontWeight: 'bold', marginBottom: '8px' }}
//                     onClick={handleToggleMessages} 
//                     sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
//                 >
//                     Messages
//                     {expandedMessages ? <ExpandLessIcon sx={{ ml: 1 }} /> : <ExpandMoreIcon sx={{ ml: 1 }} />}
//                 </Typography>
//                 <Collapse in={expandedMessages}>
//                     <List>
//                         {results.messages.map((message) => (
//                             <ListItem
//                                 button
//                                 key={message.id}
//                                 onClick={() => handleItemClick('message', message.id, message.conversation)}
//                                 sx={{ padding: '12px 16px', borderRadius: '4px', '&:hover': { backgroundColor: '#f0f0f0' } }}
//                             >
//                                 <ListItemText
//                                     primary={
//                                         <>
//                                             <UserQuestion 
//                                                 text={message.question}
//                                                 searchWords={[query]}
//                                                 autoEscape={true}
//                                             />
//                                             <ChatbotResponse
//                                                 text={message.answer}
//                                                 enableTextEffect={false}
//                                             />
//                                         </>
//                                     }
//                                 />
//                             </ListItem>
//                         ))}
//                     </List>
//                 </Collapse>
//             </div>
//         </Paper>
//     );
// };

// export default MasterSearch;



import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { TextField, Typography, Paper, List, ListItem, ListItemText, Divider, InputAdornment, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import UserQuestion from '../chatPage/useQuestion';
import ChatbotResponse from '../chatPage/chatbotResponse';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const MasterSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState({ conversations: [], messages: [] });
    const [expandedConversations, setExpandedConversations] = useState(true);
    const [expandedMessages, setExpandedMessages] = useState(true);
    const [conversationPage, setConversationPage] = useState(1);
    const [messagePage, setMessagePage] = useState(1);
    const [hasMoreConversations, setHasMoreConversations] = useState(true);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const conversationsEndRef = useRef(null);
    const messagesEndRef = useRef(null);
    const navigate = useNavigate();
    const userId = localStorage.getItem("userId");

    // Fetch conversations and messages based on pagination
    const fetchSearchResults = useCallback(() => {
        if (query.length > 0) {
            axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/search/${userId}/?q=${query}&conversationPage=${conversationPage}&messagePage=${messagePage}`)
                .then(response => {
                    console.log("search results: ", response.data);
                    setResults(prev => ({
                        conversations: [...prev.conversations, ...response.data.conversations],
                        messages: [...prev.messages, ...response.data.messages]
                    }));
                    setHasMoreConversations(response.data.hasMoreConversations);
                    setHasMoreMessages(response.data.hasMoreMessages);
                })
                .catch(error => console.error('Error fetching search results:', error));
        } else {
            setResults({ conversations: [], messages: [] });
        }
    }, [query, conversationPage, messagePage, userId]);

    useEffect(() => {
        fetchSearchResults();
    }, [fetchSearchResults]);

    // Observe when the user reaches the end of the conversation list
    const handleConversationsObserver = useCallback((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && hasMoreConversations) {
            setConversationPage(prevPage => prevPage + 1);  // Load more conversations
        }
    }, [hasMoreConversations]);

    // Observe when the user reaches the end of the message list
    const handleMessagesObserver = useCallback((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && hasMoreMessages) {
            setMessagePage(prevPage => prevPage + 1);  // Load more messages
        }
    }, [hasMoreMessages]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleConversationsObserver, { threshold: 1.0 });
        if (conversationsEndRef.current) {
            observer.observe(conversationsEndRef.current);
        }
        return () => {
            if (conversationsEndRef.current) {
                observer.unobserve(conversationsEndRef.current);
            }
        };
    }, [handleConversationsObserver]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleMessagesObserver, { threshold: 1.0 });
        if (messagesEndRef.current) {
            observer.observe(messagesEndRef.current);
        }
        return () => {
            if (messagesEndRef.current) {
                observer.unobserve(messagesEndRef.current);
            }
        };
    }, [handleMessagesObserver]);

    const handleItemClick = (type, id, conversationId = null) => {
        if (type === 'conversation') {
            window.open(`/chat/${id}`, '_blank');
        } else if (type === 'message' && conversationId) {
            window.open(`/chat/${conversationId}`, '_blank');
        }
    };

    const handleToggleConversations = () => {
        setExpandedConversations(prev => !prev);
    };

    const handleToggleMessages = () => {
        setExpandedMessages(prev => !prev);
    };

    return (
        <Paper sx={{ 
            padding: '24px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
            transition: "all 0.3s ease",
        }}>
            <TextField
                fullWidth
                variant="outlined"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search..."
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="action" />
                        </InputAdornment>
                    ),
                }}
                sx={{ 
                    backgroundColor: '#ffffff',
                    transition: "background-color 0.3s ease",
                }}
            />
            <div style={{ marginTop: '16px' }}>
                <Typography 
                    variant="h6" 
                    style={{ fontWeight: 'bold', marginBottom: '8px' }}
                    onClick={handleToggleConversations} 
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                >
                    Conversations
                    {expandedConversations ? <ExpandLessIcon sx={{ ml: 1 }} /> : <ExpandMoreIcon sx={{ ml: 1 }} />}
                </Typography>
                <Collapse in={expandedConversations}>
                    <List>
                        {results.conversations.map((conversation) => (
                            <ListItem
                                button
                                key={conversation.id}
                                onClick={() => handleItemClick('conversation', conversation.id)}
                                sx={{ padding: '12px 16px', borderRadius: '4px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                            >
                                <ListItemText
                                    primary={
                                        <Highlighter
                                            searchWords={[query]}
                                            autoEscape={true}
                                            textToHighlight={conversation.title}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                        <div ref={conversationsEndRef} /> {/* Ref to trigger loading more conversations */}
                    </List>
                </Collapse>
            </div>
            <Divider style={{ margin: '16px 0' }} />
            <div>
                <Typography 
                    variant="h6" 
                    style={{ fontWeight: 'bold', marginBottom: '8px' }}
                    onClick={handleToggleMessages} 
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                >
                    Messages
                    {expandedMessages ? <ExpandLessIcon sx={{ ml: 1 }} /> : <ExpandMoreIcon sx={{ ml: 1 }} />}
                </Typography>
                <Collapse in={expandedMessages}>
                    <List>
                        {results.messages.map((message) => (
                            <ListItem
                                button
                                key={message.id}
                                onClick={() => handleItemClick('message', message.id, message.conversation)}
                                sx={{ padding: '12px 16px', borderRadius: '4px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                            >
                                <ListItemText
                                    primary={
                                        <>
                                            <UserQuestion 
                                                text={message.question}
                                                searchWords={[query]}
                                                autoEscape={true}
                                            />
                                            <ChatbotResponse
                                                text={message.answer}
                                                enableTextEffect={false}
                                            />
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                        <div ref={messagesEndRef} /> {/* Ref to trigger loading more messages */}
                    </List>
                </Collapse>
            </div>
        </Paper>
    );
};

export default MasterSearch;

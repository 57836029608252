import React, { useState, useEffect} from "react";
import { Box, Avatar, Typography, Chip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Highlighter from "react-highlight-words";
import ArticleIcon from '@mui/icons-material/Article';



const UserQuestion = ({ text, searchWords = [], autoEscape, file}) => {

  const userName = localStorage.getItem("username");

  const [lastSentFile, setLastSentFile] = useState(null);

  // const shouldSendFile = file && (!lastSentFile || lastSentFile.name !== file.name);

  // const handleFileSend = () => {
  //   if (shouldSendFile) {
  //     setLastSentFile(file);
  //     // Logic to send file with question
  //   }
  // };

  // // Call this function when you send the message
  // handleFileSend();
  // // console.log(userName)
  useEffect(() => {
    const storedFile = localStorage.getItem("lastSentFile");
    if (storedFile) {
      const parsedFile = JSON.parse(storedFile);
      console.log("Loaded from sessionStorage on mount:", parsedFile);
      setLastSentFile(parsedFile);
    }
  }, []);

  useEffect(() => {
    if (file && (!lastSentFile || lastSentFile.name !== file.name)) {
        setLastSentFile(file);
        localStorage.setItem("lastSentFile", JSON.stringify({ name: file.name, type: file.type }));
    }
}, [file, lastSentFile]);

  return (
    <Box
      sx={{
        display: "flex",
        // alignItems: "flex-start",
        justifyContent: "flex-end",
        // margin: "10px -175px",
        margin: "10px -5px",

      }}
    >
      <Box
        sx={{
          backgroundColor: "#89CFF0",
          opacity: 5,
          padding: 1.5,
          borderRadius: 2,
          maxWidth: "70%",
          boxShadow: 3,
        }}
      >
        {/* <Typography variant="body1" textAlign={"justify"}>{text}</Typography> */}
        <Typography variant="body1" textAlign={"justify"}>
        <Highlighter
            searchWords={searchWords}
            autoEscape={autoEscape}
            // textToHighlight={textToHighlight || text}
            textToHighlight={text}
          />
        </Typography>
        {/* Display PDF name if file is present */}
        {/* {file && file.name && (
          <Box sx={{ marginTop: 1 }}>
            <Chip
              icon={<ArticleIcon />}
              label={file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
              variant="outlined"
              sx={{
                marginTop: "8px",
                backgroundColor: "white",
                borderRadius: 2,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        )} */}

          {/* {shouldSendFile && (
          <Chip
            label={file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
            variant="outlined"
            sx={{
              marginTop: 1,
              borderRadius: 1,
              backgroundColor: "#fff",
            }}
          />
        )} */}
        {lastSentFile && lastSentFile.name &&  (
                    <Chip
                        icon={<ArticleIcon />}
                        label={lastSentFile.name.length > 20 ? `${lastSentFile.name.slice(0, 20)}...` : lastSentFile.name}
                        variant="outlined"
                        sx={{
                            marginTop: 1,
                            borderRadius: 1,
                            backgroundColor: "#fff",
                        }}
                    />
                )}
      </Box>
      
      <Avatar alt={userName} src="/static/images/avatar/1.jpg" sx={{ marginLeft: 2, backgroundColor:"#00A0DC" }}>
        {/* <PersonIcon style={{ color: "black"}}/> */}
      </Avatar>
      {/* <Avatar
        alt={name}
        src="/static/images/avatar/1.jpg"
        sx={{
          width: 64,
          height: 64,
          mr: 2,
        }}
      /> */}
    </Box>
  );
};

export default UserQuestion;

// FeedbackComponent.js
import React from "react";
import { Box, Tooltip, Snackbar, Alert, useTheme, IconButton, styled } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const SaveFeedback = ({ showFeedback, handleFeedback, snackbarOpen, handleCloseSnackbar, snackbarMessage, messagesEndRef }) => {
  const theme = useTheme();

  const CustomIconButton = styled(IconButton)(({ theme, colorType }) => ({
    color:
      colorType === "good"
        ? theme.palette.success.main
        : theme.palette.error.main,
    "&:hover": {
      backgroundColor:
        colorType === "good"
          ? theme.palette.success.light
          : theme.palette.error.light,
    },
  }));

  return (
    <Box>
      <div ref={messagesEndRef} />
      {showFeedback && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Tooltip title="Like">
            <CustomIconButton
              colorType="good"
              onClick={() => handleFeedback("good")}
              sx={{ marginBottom: theme.spacing(1) }}
            >
              <ThumbUpIcon />
            </CustomIconButton>
          </Tooltip>
          <Tooltip title="Dislike">
            <CustomIconButton
              colorType="bad"
              onClick={() => handleFeedback("bad")}
              sx={{ marginBottom: theme.spacing(1) }}
            >
              <ThumbDownIcon />
            </CustomIconButton>
          </Tooltip>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%", height: "10%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SaveFeedback;

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Delete, Download } from "@mui/icons-material";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Snackbar,
  Alert,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

function DataSheets({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [datasheets, setDatasheets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const userId = localStorage.getItem("userId");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    console.log("Fetching datasheets for user:", userId);

    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/users/${userId}/datasheets/`,
      )
      .then((response) => {
        console.log("Datasheets fetched successfully:", response.data);
        setDatasheets(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching datasheets:", error);
        setError("Failed to load datasheets");
        setLoading(false);
      });
  }, [userId]);

  // const fetchDataSheets = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/users/${userId}/datasheets/`, {
  //       params: {
  //         page,
  //         search: searchTerm,
  //         sort: sortOrder,
  //       },
  //     });

  //     const { results, next } = response.data.data;  // Accessing data.results
  //     setDatasheets(prevDatasheets => [...prevDatasheets, ...results]);
  //     setHasMore(!!next);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setError('Failed to load datasheets');
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchDataSheets();
  // }, [page, searchTerm, sortOrder]);

  // Handle search and sort changes
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset page to 1 on new search
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setPage(1); // Reset page to 1 on sort change
  };

  const handleDownload = (datasheetId) => {
    console.log("Initiating download for datasheet ID:", datasheetId);

    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/datasheets/${datasheetId}/download/`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        console.log("Download response received:", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "datasheet.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //   setSuccess('Download successful');
        setSnackbarMessage("Download successful");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        console.log("Download completed successfully");
        console.log("Download completed successfully");
      })
      .catch(() => {
        console.error("Error downloading datasheet");
        setSnackbarMessage("Failed to download datasheet");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleDelete = (datasheetId) => {
    axios
      .delete(
        `${process.env.REACT_APP_PUBLIC_URL}/api/datasheets/${datasheetId}/delete/`,
      )
      .then(() => {
        setDatasheets((prevDataSheets) =>
          prevDataSheets.filter(
            (datasheet) => datasheet.datasheet_id !== datasheetId,
          ),
        );
        setSnackbarMessage("Datasheet deleted successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage("Failed to delete datasheet");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("Error deleting datasheet");
      });
  };

  const loadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  if (loading) return <CircularProgress />;
  // if (loading && !datasheets.length) return <CircularProgress />;

  return (
    <Box sx={{ padding: 3, backgroundColor: "#f4f5f7", borderRadius: 2 }}>
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
    <TextField
      label="Search by Name"
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={handleSearchChange}
      sx={{ flex: 1, marginRight: 2 }}
    />
    <FormControl size="small">
      <InputLabel>Sort By</InputLabel>
      <Select
        value={sortOrder}
        onChange={handleSortChange}
        label="Sort By"
      >
        <MenuItem value="newest">Newest First</MenuItem>
        <MenuItem value="oldest">Oldest First</MenuItem>
      </Select>
    </FormControl>
  </Box> */}

      {datasheets.length === 0 ? (
        <Typography variant="h5" sx={{ color: "#626466", textAlign: "center" }}>
          No datasheets available
        </Typography>
      ) : (
        datasheets.map((datasheet) => (
          <Card
            key={datasheet.datasheet_id}
            sx={{
              marginBottom: 3,
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              borderRadius: 2,
            }}
          >
            <CardContent sx={{ padding: "16px 24px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {datasheet.pdf_title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginTop: 1,
                  textAlign: "justify",
                  lineHeight: 1.6,
                  mb: 2,
                  color: "black",
                  "@media (max-width:600px)": {
                    fontSize: "0.875rem",
                  },
                }}
              >
                {datasheet.summary}
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: 2, color: "#a0a0a0" }}
              >
                Uploaded at: {new Date(datasheet.created_at).toLocaleString()}
              </Typography>
            </CardContent>
            <CardActions
              sx={{ justifyContent: "flex-end", padding: "8px 24px" }}
            >
              <Tooltip title="Download" arrow>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontWeight: "bold",
                    minWidth: 40,
                    padding: 1,
                    borderRadius: 1,
                  }}
                  onClick={() => handleDownload(datasheet.datasheet_id)}
                >
                  <DownloadIcon />
                </Button>
              </Tooltip>

              {/* <Tooltip title="Delete" arrow>
              <Button
                variant="contained"
                color="error"
                sx={{ fontWeight: "bold", minWidth: 40, padding: 1, borderRadius: 1, marginLeft: 1 }}
                onClick={() => handleDelete(datasheet.datasheet_id)}
              >
                <DeleteIcon />
              </Button>
            </Tooltip> */}
            </CardActions>
          </Card>
        ))
      )}

      {loading && <CircularProgress />}
      {/* {!loading && hasMore && (
        <Button onClick={loadMore} sx={{ marginTop: 2 }}>
          Load More
        </Button>
      )} */}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DataSheets;

// import React, { useState } from 'react';
// import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import EmailIcon from '@mui/icons-material/Email';
// import SendIcon from '@mui/icons-material/Send';

// const EmailModal = ({ open, handleClose, handleSendEmail, theme }) => {

//   const name = localStorage.getItem("username")
//   const companyName = localStorage.getItem("companyname"
//   )
//   const designation = localStorage.getItem("designation")
//   const userEmail = localStorage.getItem("email")
//   const mobileNumber = localStorage.getItem("mobilenumber")

//   const senderEmail = 'cssupport@cimcondigital.com';
//   const smtpServer = 'smtp.office365.com';
//   const port = 587;
//   const [emailContent, setEmailContent] = useState({
//     subject: `CIMCON Cloud Account Creation Request || ${companyName}`,
//     body: `Hello Support Team,

// I Would like to request your assistance with CIMCON Cloud account creation,
// Please find the details below:

// CIMCopilot User Details:
// User Name: ${name}
// Email ID: ${userEmail}
// Company Name: ${companyName}
// Contact Number: ${mobileNumber}

// Customer Details: 
// Customer Name:

// User Note:
// please create CIMCON Cloud account in name of CIMCON Pharma ltd and share credentials, also guide us for the dashboard creation,

// Thank you,
// `,
//   });

  

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setEmailContent({ ...emailContent, [name]: value });
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="email-modal-title"
//       aria-describedby="email-modal-description"
//       sx={{
//         backdropFilter: "blur(6px)",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Box
//         sx={{
//           width: "100%",
//           maxWidth: "600px",
//           bgcolor: "background.paper",
//           boxShadow: 24,
//           p: 4,
//           borderRadius: "16px",
//           border: `1px solid ${theme.palette.divider}`,
//           position: "relative",
//           overflow: "hidden",
//         }}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: "absolute",
//             right: 16,
//             top: 16,
//             color: theme.palette.grey[600],
//             "&:hover": {
//               color: theme.palette.primary.main,
//               backgroundColor: "rgba(0, 0, 0, 0.2)",
//             },
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography
//           variant="h5"
//           gutterBottom
//           sx={{ 
//             color: theme.palette.primary.main, 
//             display: 'flex', 
//             alignItems: 'center',
//             mb: 2 
//           }}
//         >
//           <EmailIcon sx={{ marginRight: 1, fontSize: 'inherit' }} />
//           Cloud Configuration Request
//         </Typography>
//         <TextField
//           label="Subject"
//           name="subject"
//           value={emailContent.subject}
//           onChange={handleChange}
//           fullWidth
//           variant="outlined"
//           sx={{ mb: 2 }}
//           disabled
//         />
//         <TextField
//           label="Email Body"
//           name="body"
//           value={emailContent.body}
//           onChange={handleChange}
//           multiline
//           rows={6}
//           fullWidth
//           variant="outlined"
//           sx={{ mb: 2 }}
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleSendEmail(emailContent)}
//           startIcon={<SendIcon />}
//         >
//           Send
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default EmailModal;



import React, { useState } from 'react';
import { Box, Button, TextField, Modal, Typography, IconButton, Alert, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EmailModal = ({ open, handleClose, theme }) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const userId = localStorage.getItem("userId");

  const sendQuery = async (query, userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/generate_ticket/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, user_id: userId }),
      });
      const data = await response.json();
      if (response.ok) {
        alert(data.message);
        setQuery(''); // Clear the query field on successful submission
        handleClose();
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (query.trim() === '') {
      setError('Please describe your query.');
      return;
    }
    setError('');
    setLoading(true);
    sendQuery(query, userId);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value.trim() !== '') {
      setError('');
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="query-modal-title" aria-describedby="query-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '500px',
          bgcolor: 'background.paper',
          boxShadow: 4,
          p: 3,
          borderRadius: '10px',
          border: '1px solid grey',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: theme.palette.grey[600],
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom sx={{ color: 'primary.main' }} id="query-modal-title">
          Raise Query
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="Your Query"
          value={query}
          onChange={handleQueryChange}
          sx={{ mb: 3 }}
          id="query-modal-description"
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="primary" onClick={handleClose} sx={{ mr: 1, fontWeight:"bold" }} disabled={loading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ fontWeight:"bold" }} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailModal;

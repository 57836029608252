import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

const ApprovalPending = () => {
    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={6} sx={{ padding: 4, marginTop: 8 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                        Approval Pending
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        Your email has been verified successfully. Your account is pending approval from our support team. 
                        You will receive an email once your account is approved.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default ApprovalPending;


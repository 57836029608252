import { createTheme } from '@mui/material/styles';

export const tableCellTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5", // Light gray background color
          fontWeight: "bold",          // Make text bold
          fontSize: "1rem",            // Slightly larger font size
          color: "#333",               // Darker text color
          padding: "16px",             // Adjust padding for spacing
        },
      },
    },
//     MuiTableCell: {
//         styleOverrides: {
//           root: {
//             backgroundColor: "#ffffff",    // White background color for body cells
//             fontWeight: "normal",          // Normal font weight for body cells
//             fontSize: "0.875rem",          // Slightly smaller font size for body cells
//             color: "#555",                 // Lighter text color for body cells
//             padding: "12px",               // Adjust padding for body cells
//             borderBottom: "1px solid #e0e0e0", // Light gray border between rows
//           },
//         },
//       },
  },
});


export const tableBodyCellTheme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",    // White background color for body cells
            fontWeight: "normal",          // Normal font weight for body cells
            fontSize: "0.875rem",          // Slightly smaller font size for body cells
            color: "black",                 // Lighter text color for body cells
            padding: "12px",               // Adjust padding for body cells
            borderBottom: "1px solid #e0e0e0", // Light gray border between rows
          },
        },
      },
    },
  });

// export { tableCellTheme, tableBodyCellTheme };

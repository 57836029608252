import React, { createContext, useContext, useState } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => 
    localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
  );
  const [user, setUser] = useState(() => 
    localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null
  );

  const login = (tokens) => {
    setAuthTokens(tokens);
    const decodedUser = jwtDecode(tokens.access);
    setUser(decodedUser);
    localStorage.setItem('authTokens', JSON.stringify(tokens));
    localStorage.setItem('userId', decodedUser.user_id);
    
  };

  const logout = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    console.log("removed auth tokens: ", authTokens)
    localStorage.removeItem('userId');
  };

  return (
    <AuthContext.Provider value={{ authTokens, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

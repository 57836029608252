import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Avatar, Typography, Button, Snackbar, Alert, Tooltip, CircularProgress, IconButton } from '@mui/material';
import chatbotIcon from '../../assets/images/cimcopilot_short.png'; // Adjust the path based on your directory structure
import DownloadIcon from "@mui/icons-material/Download";
import axios from 'axios';
import Highlighter from "react-highlight-words";
import { orderJsonKeysDownload } from '../Utils/ModbusUtils';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';



const ChatbotResponse = ({ text, onDownload, enableTextEffect, searchWords, autoEscape, textToHighlight }) => {

  const [loading, setLoading] = useState(true);
  // const [setLoading] = useState(true);
  const [loadingDots, setLoadingDots] = useState('');
  // const [displayText, setDisplayText] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  // const [setDisplayedText] = useState('');
  const [isDownloadable, setIsDownloadable] = useState(false);
  // const [setIsDownloadable] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [index, setIndex] = useState(0);
  const [copySuccess, setCopySuccess] = useState(null);
  const intervalRef = useRef(null);
  const conversationId =  localStorage.getItem('conversationId');










  //first version 
  // useEffect(() => {
  //   if (enableTextEffect) {
  //     // setDisplayedText('');
  //     setLoading(true);
  //     setIsDownloadable(false);
  //     if (index >= text.length) {
  //       clearInterval(intervalRef.current);
  //       intervalRef.current = null;
  //       setLoading(false);
  //       if (text.includes('You can download file')) {
  //         setIsDownloadable(true);
  //         setAlertOpen(true);
  //       }
  //       return;
  //     }

  //     intervalRef.current = setInterval(() => {
  //       setDisplayedText((prev) => prev + text.charAt(index));
  //       setIndex((prevIndex) => prevIndex + 1);
  //     }, 10);

  //     return () => clearInterval(intervalRef.current);
  //   } else {
  //     setDisplayedText(text);
  //     setLoading(false);
  //   }

  // }, [index, text]);


  

  useEffect(() => {
    if (enableTextEffect) {
      setLoading(true);
      setIsDownloadable(false);
   
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          setIndex((prevIndex) => {
            if (prevIndex >= text.length) {
              clearInterval(intervalRef.current);
              intervalRef.current = null;
              setLoading(false);
   
              // Check for the condition to enable download
              if (text.includes('You can download file')) {
                setIsDownloadable(true);
                setAlertOpen(true);
              }
              return prevIndex;
            }
            // Update displayed text with the next character
            setDisplayedText((prev) => prev + text.charAt(prevIndex));
            return prevIndex + 1;
          });
        }, 10);
      }
   
      // Cleanup on component unmount or when effect dependencies change
      return () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      };
    } else {
      setDisplayedText(text);
      setLoading(false);
    }
  }, [enableTextEffect, text]);

  



  // useEffect(() => {
  //   const dotsInterval = setInterval(() => {
  //     setLoadingDots(getLoadingDots());
  //   }, 500);

  //   return () => clearInterval(dotsInterval);
  // }, []);

  // const printData = () => {
  //   setDisplayedText("");
  //   setIndex(0);
  // };


  // useEffect(() => {
  //   let index = -1;
  //   setDisplayedText('');
  //   setLoading(true);
  //   setIsDownloadable(false);

  //   const interval = setInterval(() => {
  //     setDisplayedText((prev) => prev + text.charAt(index));
  //     index += 1;
  //     if (index === text.length) {
  //       clearInterval(interval);
  //       setLoading(false);
  //       if (text.includes('You can download it')) {
  //         setIsDownloadable(true);
  //         setAlertOpen(true);
  //       }
  //     }
  //   }, 5); 

  //   return () => clearInterval(interval);
  // }, [text]);



  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  

  const handleDownloadConfig = async () => {
    setIsDownloading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/${conversationId}/`,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
            // 'Authorization': `Bearer ${access}`
          },
        }
      );
      setDeviceName(response.data.device_name);
  
      console.log("Response:", response.data);
  
      const configurationPayload = response.data.configuration_payload;
      const deviceType = response.data.device_type;
  
      // Check if the device type is 'modbusrtu' or 'modbustcpip' before applying the custom order
      let payloadToDownload;
      if (deviceType === "modbusrtu" || deviceType === "modbustcpip") {
        // Ensure strict key order for modbusrtu or modbustcpip
        const orderedPayload = orderJsonKeysDownload(configurationPayload.export_device, deviceType);
        payloadToDownload = orderedPayload;
        console.log("paylod to download: ", payloadToDownload)
      } else {
        // For other device types, use the payload as is
        payloadToDownload = configurationPayload;
      }
  
      const blob = new Blob([JSON.stringify(payloadToDownload, null, 2)], {
        type: "application/json",
      });
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement("a");
      a.href = url;
      a.download = `${response.data.device_name}_${deviceType}.json`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the configuration file:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
    setIsDownloading(false);
  };
  


  const getLoadingDots = () => {
    const dots = ['.', '..', '...'];
    const currentIndex = Math.floor((Date.now() / 500) % dots.length);
    return dots[currentIndex];
  };


  // const highlightedText = (
  //   <Highlighter
  //     searchWords={searchWords}
  //     autoEscape={autoEscape}
  //     // textToHighlight={textToHighlight || displayedText}
  //     textToHighlight={displayedText}

  //   />
  // );

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopySuccess(code); // Track copied code block to show success
      setTimeout(() => setCopySuccess(null), 2000); // Reset success after 2 seconds
    }).catch((err) => {
      console.error('Failed to copy!', err);
    });
  };

  // Function to get the language label from the className
  const getLanguageLabel = (language) => {
    switch (language) {
      case 'python':
        return 'Python';
      case 'javascript':
        return 'JavaScript';
      case 'html':
        return 'HTML';
      case 'css':
        return 'CSS';
      case 'json':
        return 'JSON';
      default:
        return 'Code';
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
      <Avatar src={chatbotIcon} alt="Chatbot Icon" sx={{ marginRight: 2 }} />
      <Box sx={{
         backgroundColor: '#E0F7FA', 
        //  padding: 1.5,
         paddingRight: 1,
         paddingLeft: 1, 
         borderRadius: 2, 
         maxWidth: '70%',
          boxShadow:3 
          }}>
        {/* <Typography variant="body1" dangerouslySetInnerHTML={{ __html: text }} /> */}
        
        {/* <ReactMarkdown>{displayedText}</ReactMarkdown>
         */}
         <ReactMarkdown
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              const language = match ? match[1] : ''; // Extract the language from the className
              const codeContent = String(children).replace(/\n$/, '');
              
              return !inline && match ? (
                //version 1 
                // <Box sx={{ position: 'relative', mb: 2 }}>
                //   {/* Display the language label above the code block */}
                //   <Typography variant="caption" sx={{ fontWeight: 'bold', display: 'block', mb: 1 }}>
                //     {getLanguageLabel(language)}
                //   </Typography>
                //   <SyntaxHighlighter
                //     style={materialDark}
                //     language={language}
                //     PreTag="div"
                //     {...props}
                //   >
                //     {codeContent}
                //   </SyntaxHighlighter>
                  
                //   {/* Tooltip and copy icon with success message */}
                //   <Tooltip title={copySuccess === codeContent ? "Code copied!" : "Copy code"} arrow>
                //     <IconButton
                //       onClick={() => copyToClipboard(codeContent)}
                //       sx={{ position: 'absolute', top: 5, right: 5, color: 'white' }}
                //       size="small"
                //     >
                //       {copySuccess === codeContent ? <CheckIcon /> : <CopyIcon />}
                //     </IconButton>
                //   </Tooltip>
                  
                //   {copySuccess === codeContent && (
                //     <Typography variant="caption" sx={{ color: 'green', display: 'inline-block', ml: 1 }}>
                //       Code copied!
                //     </Typography>
                //   )}
                // </Box>
                

                //version 2
                <Box sx={{ position: 'relative', mb: 2, paddingTop: 3 }}>
                {/* Display the language label and copy icon together above the code block */}
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', top: -8, left: 0, padding: '8px', backgroundColor: '#e0f7fa', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                    {getLanguageLabel(language)}
                  </Typography>
                  <Tooltip title={copySuccess === codeContent ? "Code copied!" : "Copy code"} arrow>
                    <IconButton
                      onClick={() => copyToClipboard(codeContent)}
                      sx={{ color: 'black' }}
                      size="small"
                    >
                      {copySuccess === codeContent ? <CheckIcon /> : <CopyIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>

                <SyntaxHighlighter
                  style={materialDark}
                  language={language}
                  PreTag="div"
                  {...props}
                >
                  {codeContent}
                </SyntaxHighlighter>
                </Box>

              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }
          }}
        >
          {displayedText}
        </ReactMarkdown>

        {/* <ReactMarkdown>{highlightedText}</ReactMarkdown> */}

        {/* <ReactMarkdown>
        <Highlighter
            searchWords={searchWords}
            autoEscape={autoEscape}
            textToHighlight={textToHighlight || displayedText}
            // textToHighlight={displayedText}
          />
        </ReactMarkdown> */}
        {/* {loading ? (
          <Typography variant="body1"><ReactMarkdown>{displayedText}|</ReactMarkdown></Typography>
        ) : (
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: displayedText }} />
        )} */}
        {/* <Typography variant="body1">{displayedText}</Typography> */}


      

        {/* {isDownloadable && (
          <Tooltip title="Download Configuration">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadConfig}
            sx={{ marginTop: 2 }}
          >
            <DownloadIcon />
            {deviceName} Download
          </Button>
          </Tooltip>
        )} */}
        {/* {isDownloadable ? (
          isDownloading ? (
            <CircularProgress sx={{ marginTop: 2 }} />
          ) : (
            <Tooltip title="Download Configuration">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadConfig}
                sx={{ marginTop: 2 }}
              >
                <DownloadIcon />
                {deviceName} Download
              </Button>
            </Tooltip>
          )
        ) : (
          <CircularProgress sx={{ marginTop: 2 }} />
        )} */}
        {text.includes('You can download file') && (
          isDownloading ? (
            <CircularProgress sx={{ marginTop: 2 }} />
          ) : (
            <Tooltip title={`Download Configuration ${deviceName}`}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadConfig}
                sx={{ marginTop: 2 }}
              >
                <DownloadIcon />
                {deviceName}
              </Button>
            </Tooltip>
          )
        )}
        
        <Snackbar open={alertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} variant="filled" severity="success" sx={{ width: "100%", height: "10%" }}>
          Configuration file is available for download!
        </Alert>
      </Snackbar>
      </Box>
    </Box>
  );
};

export default ChatbotResponse;



// import React from 'react';
// import { useState, useEffect, useRef } from 'react';
// import ReactMarkdown from 'react-markdown';
// import { Box, Avatar, Button, Snackbar, Alert, Tooltip, CircularProgress } from '@mui/material';
// import chatbotIcon from '../../assets/images/cimcopilot_short.png'; // Adjust the path based on your directory structure
// import DownloadIcon from "@mui/icons-material/Download";
// import axios from 'axios';
// import Highlighter from "react-highlight-words";
// import remarkGfm from 'remark-gfm';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

// const ChatbotResponse = ({ text, onDownload, enableTextEffect, searchWords, autoEscape, textToHighlight }) => {

//   const [loading, setLoading] = useState(true);
//   const [loadingDots, setLoadingDots] = useState('');
//   const [displayedText, setDisplayedText] = useState('');
//   const [isDownloadable, setIsDownloadable] = useState(false);
//   const [alertOpen, setAlertOpen] = useState(false);
//   const [deviceName, setDeviceName] = useState('');
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [index, setIndex] = useState(0);
//   const intervalRef = useRef(null);
//   const conversationId = localStorage.getItem('conversationId');

//   useEffect(() => {
//     if (enableTextEffect) {
//       setLoading(true);
//       setIsDownloadable(false);
//       if (index >= text.length) {
//         clearInterval(intervalRef.current);
//         intervalRef.current = null;
//         setLoading(false);
//         if (text.includes('You can download it')) {
//           setIsDownloadable(true);
//           setAlertOpen(true);
//         }
//         return;
//       }

//       intervalRef.current = setInterval(() => {
//         setDisplayedText((prev) => prev + text.charAt(index));
//         setIndex((prevIndex) => prevIndex + 1);
//       }, 1);

//       return () => clearInterval(intervalRef.current);
//     } else {
//       setDisplayedText(text);
//       setLoading(false);
//     }
//   }, [index, text]);

//   const handleAlertClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setAlertOpen(false);
//   };

//   const handleDownloadConfig = async () => {
//     setIsDownloading(true);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/${conversationId}/`,
//         {
//           headers: {
//             // Authorization: `Bearer ${token}`,
//           },
//         },
//       );
//       setDeviceName(response.data.device_name);

//       const configurationPayload = response.data.configuration_payload;
//       const blob = new Blob([JSON.stringify(configurationPayload, null, 2)], {
//         type: "application/json",
//       });
//       const url = window.URL.createObjectURL(blob);

//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${response.data.device_name}_${response.data.device_type}.json`;
//       document.body.appendChild(a);
//       a.click();
//       a.remove();
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error("Error downloading the configuration file:", error.message);
//       if (error.response) {
//         console.error("Response data:", error.response.data);
//       }
//     }
//     setIsDownloading(false);
//   };

//   const getLoadingDots = () => {
//     const dots = ['.', '..', '...'];
//     const currentIndex = Math.floor((Date.now() / 500) % dots.length);
//     return dots[currentIndex];
//   };

//   return (
//     <Box sx={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
//       <Avatar src={chatbotIcon} alt="Chatbot Icon" sx={{ marginRight: 2 }} />
//       <Box sx={{
//         backgroundColor: '#E0F7FA',
//         paddingRight: 1,
//         paddingLeft: 1,
//         borderRadius: 2,
//         maxWidth: '70%',
//         boxShadow: 3
//       }}>
//         <ReactMarkdown
//           remarkPlugins={[remarkGfm]}
//           components={{
//             code({ inline, className, children, ...props }) {
//               const match = /language-(\w+)/.exec(className || '');
//               return !inline && match ? (
//                 <SyntaxHighlighter
//                   style={solarizedlight}
//                   language={match[1]}
//                   PreTag="div"
//                   {...props}
//                 >
//                   {String(children).replace(/\n$/, '')}
//                 </SyntaxHighlighter>
//               ) : (
//                 <code className={className} {...props}>
//                   {children}
//                 </code>
//               );
//             }
//           }}
//         >
//           {displayedText}
//         </ReactMarkdown>

//         {text.includes('You can download it') && (
//           isDownloading ? (
//             <CircularProgress sx={{ marginTop: 2 }} />
//           ) : (
//             <Tooltip title={`Download Configuration ${deviceName}`}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={handleDownloadConfig}
//                 sx={{ marginTop: 2 }}
//               >
//                 <DownloadIcon />
//                 {deviceName}
//               </Button>
//             </Tooltip>
//           )
//         )}

//         <Snackbar open={alertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleAlertClose}>
//           <Alert onClose={handleAlertClose} variant="filled" severity="success" sx={{ width: "100%", height: "10%" }}>
//             Configuration file is available for download!
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Box>
//   );
// };

// export default ChatbotResponse;




// import React, { useState, useEffect } from 'react';
// import { Box, Avatar, Typography, Button, Snackbar, Alert, Tooltip } from '@mui/material';
// import chatbotIcon from '../../assets/images/cimcopilot_short.png'; // Adjust the path based on your directory structure
// import DownloadIcon from '@mui/icons-material/Download';
// import axios from 'axios';

// const ChatbotResponse = ({ text }) => {
//     const [loading, setLoading] = useState(true);
//     const [displayedText, setDisplayedText] = useState('');
//     const [isDownloadable, setIsDownloadable] = useState(false);
//     const [alertOpen, setAlertOpen] = useState(false);
//     const [configIds, setConfigIds] = useState([]);
//     const [configData, setConfigData] = useState([]);

//     // useEffect(() => {
//     //     let index = 0;
//     //     setDisplayedText('');
//     //     setLoading(true);
//     //     setIsDownloadable(false);

//     //     const interval = setInterval(() => {
//     //         setDisplayedText((prev) => prev + text.charAt(index));
//     //         index += 1;
//     //         if (index === text.length) {
//     //             clearInterval(interval);
//     //             setLoading(false);
//     //             if (text.includes('You can download it')) {
//     //                 setIsDownloadable(true);
//     //                 setAlertOpen(true);
//     //             }
//     //         }
//     //     }, 50); // Adjust typing speed here (50ms per character)

//     //     return () => clearInterval(interval);
//     // }, [text]);

//     useEffect(() => {
//       let index = 0;
//       setDisplayedText('');
//       setLoading(true);
//       setIsDownloadable(false);

//       const interval = setInterval(() => {
//           setDisplayedText((prev) => prev + text.charAt(index));
//           index += 1;
//           if (index === text.length) {
//               clearInterval(interval);
//               setLoading(false);
//               if (text.includes('You can download it')) {
//                   setIsDownloadable(true);
//                   setAlertOpen(true);
//                   fetchConfigData();
//               }
//           }
//       }, 50); // Adjust typing speed here (50ms per character)

//       return () => clearInterval(interval);
//   }, [text]);

//   const fetchConfigData = async () => {
//     try {
//         const response = await axios.post(
//             'http://${process.env.REACT_APP_PUBLIC_URL}/api/get_multiple_device_configs/',
//             { config_ids: configIds },  // Pass the configId in the request body
//             {
//                 headers: {
//                     // Authorization: `Bearer ${token}`,
//                 },
//             },
//         );
//         console.log("config ids: ", configIds)
//         setConfigData(response.data);
//     } catch (error) {
//       console.log("config ids: ", configIds)
//         console.error("Error fetching the configuration data:", error.message);
//         if (error.response) {
//             console.error("Response data:", error.response.data);
//         }
//     }
// };

// const handleDownloadConfig = (config) => {
//   try {
//       const configurationPayload = config.configuration_payload;
//       const blob = new Blob([JSON.stringify(configurationPayload, null, 2)], {
//           type: "application/json",
//       });
//       const url = window.URL.createObjectURL(blob);

//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${config.device_name}_${config.device_type}.json`;
//       document.body.appendChild(a);
//       a.click();
//       a.remove();
//       window.URL.revokeObjectURL(url);
//   } catch (error) {
//       console.error("Error downloading the configuration file:", error.message);
//   }
// };


//     const handleAlertClose = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setAlertOpen(false);
//     };

//     // const handleDownloadConfig = async (configId) => {
//     //     try {
//     //         const response = await axios.get(
//     //             `http://${process.env.REACT_APP_PUBLIC_URL}/api/get_multiple_device_configs/${configId}/`,
//     //             {
//     //                 headers: {
//     //                     // Authorization: `Bearer ${token}`,
//     //                 },
//     //             },
//     //         );

//     //         console.log("Response:", response.data);

//     //         const configurationPayload = response.data.configuration_payload;
//     //         const blob = new Blob([JSON.stringify(configurationPayload, null, 2)], {
//     //             type: "application/json",
//     //         });
//     //         const url = window.URL.createObjectURL(blob);

//     //         const a = document.createElement("a");
//     //         a.href = url;
//     //         a.download = `${response.data.device_name}_${response.data.device_type}.json`;
//     //         document.body.appendChild(a);
//     //         a.click();
//     //         a.remove();
//     //         window.URL.revokeObjectURL(url);
//     //     } catch (error) {
//     //         console.error("Error downloading the configuration file:", error.message);
//     //         if (error.response) {
//     //             console.error("Response data:", error.response.data);
//     //         }
//     //     }
//     // };

//   //   const handleDownloadConfig = async (configId) => {
//   //     try {
//   //         const response = await axios.post(
//   //             'http://${process.env.REACT_APP_PUBLIC_URL}/api/get_multiple_device_configs/',
//   //             { config_ids: [configId] },  // Pass the configId in the request body
//   //             {
//   //                 headers: {
//   //                     // Authorization: `Bearer ${token}`,
//   //                 },
//   //             },
//   //         );

//   //         const configurationPayload = response.data[0].configuration_payload;  // Access the first (and only) item in the response array
//   //         const blob = new Blob([JSON.stringify(configurationPayload, null, 2)], {
//   //             type: "application/json",
//   //         });
//   //         const url = window.URL.createObjectURL(blob);

//   //         const a = document.createElement("a");
//   //         a.href = url;
//   //         a.download = `${response.data[0].device_name}_${response.data[0].device_type}.json`;  // Access the first item
//   //         document.body.appendChild(a);
//   //         a.click();
//   //         a.remove();
//   //         window.URL.revokeObjectURL(url);
//   //     } catch (error) {
//   //         console.error("Error downloading the configuration file:", error.message);
//   //         if (error.response) {
//   //             console.error("Response data:", error.response.data);
//   //         }
//   //     }
//   // };

//     return (
//         <Box sx={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
//             <Avatar src={chatbotIcon} alt="Chatbot Icon" sx={{ marginRight: 2 }} />
//             <Box sx={{ backgroundColor: '#E0F7FA', padding: 1.5, borderRadius: 2, maxWidth: '90%' }}>
//                 <Typography variant="body1" dangerouslySetInnerHTML={{ __html: text }} />
//                 {isDownloadable && configData.map((config) => (
//                     <Tooltip key={config.id} title={`Download Configuration ${config.id}`}>
//                         <Button
//                             variant="contained"
//                             color="secondary"
//                             onClick={() => handleDownloadConfig(config)}
//                             sx={{ marginTop: 2 }}
//                         >
//                             <DownloadIcon />
//                             Download Configuration {config.id}
//                         </Button>
//                     </Tooltip>
//                 ))}

//                 <Snackbar open={alertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleAlertClose}>
//                     <Alert onClose={handleAlertClose} severity="success" sx={{ width: "100%", height: "10%" }}>
//                         Configuration file is available for download!
//                     </Alert>
//                 </Snackbar>
//             </Box>
//         </Box>
//     );
// };

// export default ChatbotResponse;




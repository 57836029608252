// ChatContext.js
import React, { createContext, useState, useContext } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [welcomeDisplayed, setWelcomeDisplayed] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const resetChat = () => {
    setMessages([]);
    setWelcomeDisplayed(false);
    setShowFeedback(false);
    setConversations([]);
    setIsLoading(false);
    setConversationId(null);
  };

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        welcomeDisplayed,
        setWelcomeDisplayed,
        showFeedback,
        setShowFeedback,
        conversations,
        setConversations,
        isLoading,
        setIsLoading,
        conversationId,
        setConversationId,
        resetChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);

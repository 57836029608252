// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import HomePage from './components/homePage/homePage';
import LoginPage from './components/login/loginPage';
import SignUpPage from './components/signup/SignUpPage';
import ChatPage from './components/chatPage/ChatPage';
import ConfigurationsPage from './components/configurationPage/configurationPage';
import AddCIMDevice from './components/cimPage/addCIMDevice';
import VerifyOTPPage from './components/otpVerification/verifyOtp';
import ApprovalPending from './components/ApprovalPending/ApprovalPending';// import AddNewChat from './components/chatPage/AddNewChat';

import ApproveUserPage from './components/ApprovalUserPage/ApprovalUserPage';
import OpenSearchedChat from './components/MasterSearch/OpenSearchedChat';
import ResetPasswordPage from './components/ResetPassword/ResetPasswordPage';
import ForgotPasswordPage from './components/ForgetPassword/ForgetPasswordPage';
import HardwareIP from './components/HardwareIP/HArdwareIP';
function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/"  component={<HomePage />} /> */}
        <Route path="/" exact element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/configuration" element={<ConfigurationsPage />} />
        <Route path="/device" element={<AddCIMDevice />} />
        <Route path="/verifyOtp" element={<VerifyOTPPage />} />
        <Route path="/approvalPending" element={<ApprovalPending />} />
        <Route path="/approve_user/:token/" element={<ApproveUserPage />} />
        <Route path="/chat/:id" element={<OpenSearchedChat />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/forget-password" element={<ForgotPasswordPage />} />
        <Route path="/HardwareIP" element={<HardwareIP />} />

        {/* <Route path="/chat/message/:id" element={<OpenSearchedChat />} /> */}

        {/* <Route path="/addnewchat" element={<AddNewChat />} /> */}

      </Routes>
    </Router>
  );
}

export default App;

import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, Paper, Alert, InputAdornment, IconButton } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import Visibility from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const theme = createTheme({
  palette: {
    primary: {
      main: "#1aa1db",
    },
    secondary: {
      main: "#c0eaeb",
    },
  },
});

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

//   const handleResetPassword = async (event) => {
//     event.preventDefault();

//     if (newPassword !== confirmPassword) {
//       setError("Passwords do not match.");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PUBLIC_URL}/api/reset_password/`,
//         { email, otp, newPassword }
//       );
//       setMessage(response.data.message);
//       setError("");
//       alert("Password reset successful. Now you can login");
//       navigate("/login");
//     } catch (error) {
//       setMessage("");
//       setError(error.response?.data?.error || "An unexpected error occurred.");
//     }
//   };
const handleResetPassword = async (event) => {
    event.preventDefault();
  
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/reset_password/`,
        {
          email,
          otp,
          new_password: newPassword, // Use `new_password` to match backend field
          confirm_password: confirmPassword, // Ensure this matches the expected field name
        }
      );
      setMessage(response.data.message);
      setError("");
      alert("Password reset successful. Now you can login");
      navigate("/login");
    } catch (error) {
      setMessage("");
      setError(error.response?.data?.error || "An unexpected error occurred.");
    }
  };
  

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ padding: 4, marginTop: 12 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h5">Reset Password</Typography>
            <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 3 }}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{ startAdornment: <EmailIcon position="start" /> }}
              />
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                InputProps={{ startAdornment: <ConfirmationNumberIcon position="start" />}}
              />
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                    startAdornment: <LockIcon position="start" />,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              />
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                    startAdornment: <LockIcon position="start" />,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              />
              <Button type="submit" variant="contained" sx={{ mt: 3, fontWeight: "bold", color: "white" }}>
                Reset Password
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;

import React from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const ConversationFilterDropdown = ({ filteredConversations, selectedFilter, handleFilterChange }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="filter-label">Filter Conversations</InputLabel>
        <Select
          labelId="filter-label"
          value={selectedFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
          label="Filter Conversations"
        >
          {Object.keys(filteredConversations).map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ConversationFilterDropdown;

import React, { useState, useEffect } from "react";
// import DeviceForm from "./DeviceForm";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Snackbar,
} from "@mui/material";
import DeviceForm from "./addCIMDevice";
// import DeleteIcon from '@mui/icons-material/Delete';
// import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import {
  Save as SaveIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  NetworkPing as NetworkPingIcon,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { tableCellTheme, tableBodyCellTheme } from "../../theme/tableCellTheme";
// import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#C0EAEB", // ThingsBoard secondary color
    },
    // background: {
    //   default: '#F4F6F9', // Light background color
    // },
    // text: {
    //   primary: '#333333', // Dark text color
    // }
  },
});

const FleetManager = () => {
  const [devices, setDevices] = useState([]);
  const [pingStatus, setPingStatus] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editDeviceId, setEditDeviceId] = useState(null);
  const [editedDevice, setEditedDevice] = useState({});
  //   const [snackbarMessage, setSnackbarMessage] = useState('');
  //   const [snackbarOpen, setSnackbarOpen] = useState(false);
  //   const [userId, setUserId] = useState(5); // Replace with actual user ID
  const userId = localStorage.getItem("userId");

  const fetchDevices = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/get/${userId}/`,
        {
          headers: {
            // 'Authorization': `Bearer ${access}`,  // Include token here if using JWT
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch devices");
      }

      const data = await response.json();
      setDevices(data);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  useEffect(() => {
    fetchDevices(userId);
  }, [userId]);

  const handleDeviceAdded = (userId) => {
    fetchDevices(userId);
  };

  const handlePing = async (ipAddress, deviceId) => {
    try {
      // Retrieve user_id from local storage
      const userId = localStorage.getItem('userId'); // Make sure to replace 'user_id' with the actual key used
  
      // Check if user_id exists
      if (!userId) {
        throw new Error("User ID not found in local storage");
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/ping/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({ 
            ip_address: ipAddress,
            user_id: userId // Pass user_id here
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to ping device");
      }
  
      const data = await response.json();
      setPingStatus((prev) => ({ ...prev, [deviceId]: data.status }));
      setSnackbarMessage(
        `Device ${data.status === "online" ? "connected" : "disconnected"}`
      );
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error pinging device:", error);
    }
  };
  

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDelete = async (deviceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/delete/${deviceId}/`,
        {
          method: "DELETE",
        },
      );

      if (!response.ok) {
        throw new Error("Failed to delete device");
      }

      // After successful deletion, fetch the updated devices list
      fetchDevices(userId);
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };

  const handleEditChange = (e, field) => {
    setEditedDevice({
      ...editedDevice,
      [field]: e.target.value,
    });
  };

  const handleEdit = (deviceId, device) => {
    setEditDeviceId(deviceId);
    setEditedDevice(device); // Initialize with current values
  };

  const handleSave = async (deviceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/update/${deviceId}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedDevice),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to update device");
      }

      const updatedDevice = await response.json();
      setDevices((prevDevices) =>
        prevDevices.map((device) =>
          device.device_id === deviceId ? updatedDevice : device,
        ),
      );
      setEditDeviceId(null); // Exit edit mode
    } catch (error) {
      console.error("Error updating device:", error);
    }
  };

  const handleCancel = () => {
    setEditDeviceId(null); // Exit edit mode
    setEditedDevice({});
  };

  const handleKeyDown = (e, deviceId) => {
    if (e.key === "Enter") {
      handleSave(deviceId);
    }
  };

  //   const handleDeviceAdded = (userId) => {
  //     fetchDevices(userId);
  //   };

  return (
    // <div>
    // // <Box sx={{ padding: 2 }}>

    // //   <TableContainer component={Paper}>
    // //     <Table>
    // //       <TableHead>
    // //         <TableRow>
    // //         <TableCell>Sr.No.</TableCell> {/* Row number */}
    // //           <TableCell>Device Name</TableCell>
    // //           <TableCell>IP Address</TableCell>
    // //           <TableCell>MAC ID</TableCell>
    // //           <TableCell>Serial Number</TableCell>
    // //           {/* <TableCell>Description</TableCell> */}
    // //           <TableCell>Created At</TableCell>
    // //           <TableCell>Status</TableCell>
    // //           <TableCell>Actions</TableCell>
    // //           <TableCell>Edit</TableCell>
    // //           <TableCell>Delete</TableCell>
    // //         </TableRow>
    // //       </TableHead>
    // //       <TableBody>
    // //         {devices.map((device, index) => (
    // //           <TableRow key={device.device_id}>
    // //             <TableCell>{index + 1}</TableCell> {/* Display row number */}
    // //             <TableCell>{editDeviceId === device.device_id ? (
    // //                 <TextField
    // //                   value={editedDevice.device_name || ''}
    // //                   onChange={(e) => handleEditChange(e, 'device_name')}
    // //                 />
    // //               ) : (
    // //                 device.device_name
    // //               )}</TableCell>
    // //             <TableCell>{editDeviceId === device.device_id ? (
    // //                 <TextField
    // //                   value={editedDevice.ip_address || ''}
    // //                   onChange={(e) => handleEditChange(e, 'ip_address')}
    // //                 />
    // //               ) : (
    // //                 device.ip_address
    // //               )}</TableCell>
    // //             <TableCell>{editDeviceId === device.device_id ? (
    // //                 <TextField
    // //                   value={editedDevice.mac_id || ''}
    // //                   onChange={(e) => handleEditChange(e, 'mac_id')}
    // //                 />
    // //               ) : (
    // //                 device.mac_id
    // //               )}</TableCell>
    // //             <TableCell>{editDeviceId === device.device_id ? (
    // //                 <TextField
    // //                   value={editedDevice.serial_number || ''}
    // //                   onChange={(e) => handleEditChange(e, 'serial_number')}
    // //                 />
    // //               ) : (
    // //                 device.serial_number
    // //               )}</TableCell>
    // //             {/* <TableCell>{device.description}</TableCell> */}
    // //             <TableCell>{new Date(device.created_at).toLocaleString()}</TableCell>
    // //             <TableCell>
    // //               {pingStatus[device.device_id] === 'online' ? (
    // //                 <Typography color="success.main">Connected</Typography>
    // //               ) : pingStatus[device.device_id] === 'offline' ? (
    // //                 <Typography color="error.main">Disconnected</Typography>
    // //               ) : (
    // //                 <Typography>Unknown</Typography>
    // //               )}
    // //             </TableCell>
    // //             <TableCell>
    // //                 <Tooltip title="ping device" arrow>
    // //               <Button
    // //                 variant="contained"
    // //                 color="secondary"
    // //                 onClick={() => handlePing(device.ip_address, device.device_id)}
    // //                 startIcon={<NetworkPingIcon/>}
    // //               >
    // //                ping
    // //               </Button>
    // //               </Tooltip>
    // //               </TableCell>

    // //               <TableCell>
    // //               {editDeviceId === device.device_id ? (
    // //                 <>
    // //                   <Button variant="contained" color="secondary" onClick={() => handleSave(device.device_id)}>
    // //                     Save
    // //                   </Button>
    // //                   <Button variant="contained" color="secondary" onClick={handleCancel}>
    // //                     Cancel
    // //                   </Button>
    // //                 </>
    // //               ) : (
    // //                 <Button variant="contained" color="secondary" onClick={() => handleEdit(device.device_id, device)}>
    // //                   Edit
    // //                 </Button>
    // //               )}
    // //             </TableCell>

    // //               <TableCell>
    // //               <Button
    // //                 variant="contained"
    // //                 color="error"
    // //                 onClick={() => handleDelete(device.device_id)}
    // //                 sx={{ ml: 2 }}
    // //                 startIcon={<DeleteIcon/>}
    // //               >
    // //                 Delete
    // //               </Button>
    // //             {/* </TableCell> */}
    // //             </TableCell>
    // //           </TableRow>
    // //         ))}
    // //       </TableBody>
    // //     </Table>
    // //   </TableContainer>
    // //   <DeviceForm userId={userId} onDeviceAdded={handleDeviceAdded} />
    // // </Box>
    // </div>
    <Box sx={{ padding: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <ThemeProvider theme={tableCellTheme}>
              <TableRow>
                <TableCell>Sr.No.</TableCell> {/* Row number */}
                <TableCell>Device Name</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>MAC ID</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ping</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </ThemeProvider>
          </TableHead>
          <TableBody>
            {/* <ThemeProvider theme = {tableBodyCellTheme}> */}
            {devices.map((device, index) => (
              <TableRow key={device.device_id}>
                <TableCell>{index + 1}</TableCell> {/* Display row number */}
                <TableCell>
                  {editDeviceId === device.device_id ? (
                    <TextField
                      value={editedDevice.device_name || ""}
                      onChange={(e) => handleEditChange(e, "device_name")}
                      onKeyDown={(e) => handleKeyDown(e, device.device_id)}
                    />
                  ) : (
                    device.device_name
                  )}
                </TableCell>
                <TableCell>
                  {editDeviceId === device.device_id ? (
                    <TextField
                      value={editedDevice.ip_address || ""}
                      onChange={(e) => handleEditChange(e, "ip_address")}
                      onKeyDown={(e) => handleKeyDown(e, device.device_id)}
                    />
                  ) : (
                    device.ip_address
                  )}
                </TableCell>
                <TableCell>
                  {editDeviceId === device.device_id ? (
                    <TextField
                      value={editedDevice.mac_id || ""}
                      onChange={(e) => handleEditChange(e, "mac_id")}
                      onKeyDown={(e) => handleKeyDown(e, device.device_id)}
                    />
                  ) : (
                    device.mac_id
                  )}
                </TableCell>
                <TableCell>
                  {editDeviceId === device.device_id ? (
                    <TextField
                      value={editedDevice.serial_number || ""}
                      onChange={(e) => handleEditChange(e, "serial_number")}
                      onKeyDown={(e) => handleKeyDown(e, device.device_id)}
                    />
                  ) : (
                    device.serial_number
                  )}
                </TableCell>
                <TableCell>
                  {new Date(device.created_at).toLocaleString()}
                </TableCell>
                <TableCell>
                  {pingStatus[device.device_id] === "online" ? (
                    <Typography color="success.main">Connected</Typography>
                  ) : pingStatus[device.device_id] === "offline" ? (
                    <Typography color="error.main">Disconnected</Typography>
                  ) : (
                    <Typography>Unknown</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Ping device" arrow>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        fontWeight: "bold",
                        minWidth: 40,
                        padding: 1,
                        borderRadius: 1,
                      }}
                      onClick={() =>
                        handlePing(device.ip_address, device.device_id)
                      }
                    >
                      <NetworkPingIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {editDeviceId === device.device_id ? (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Save" arrow>
                          <IconButton
                            sx={{
                              backgroundColor: theme.palette.secondary.main, // Green color for save
                              color: "black",
                              fontWeight: "bold",
                              minWidth: 40,
                              padding: 1,
                              borderRadius: 1,
                              boxShadow: 1,
                              "&:hover": {
                                backgroundColor: theme.palette.secondary.dark, // Darker green on hover
                              },
                            }}
                            onClick={() => handleSave(device.device_id)}
                          >
                            <SaveIcon color="black" />
                          </IconButton>
                        </Tooltip>

                        {/* Cancel Button */}
                        <Tooltip title="Cancel" arrow>
                          <IconButton
                            sx={{
                              backgroundColor: theme.palette.secondary.main, // Red color for cancel
                              color: "black",
                              fontWeight: "bold",
                              minWidth: 40,
                              padding: 1,
                              borderRadius: 1,
                              marginLeft: 1, // Add a little space between the two buttons
                              boxShadow: 1,
                              "&:hover": {
                                // backgroundColor: '#d32f2f', // Darker red on hover
                                backgroundColor: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleCancel}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </>
                  ) : (
                    <Tooltip title="Edit" arrow>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontWeight: "bold",
                          minWidth: 40,
                          padding: 1,
                          borderRadius: 1,
                        }}
                        onClick={() => handleEdit(device.device_id, device)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Delete" arrow>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        fontWeight: "bold",
                        minWidth: 40,
                        padding: 1,
                        borderRadius: 1,
                      }}
                      onClick={() => handleDelete(device.device_id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {/* </ThemeProvider> */}
          </TableBody>
        </Table>
      </TableContainer>

      <DeviceForm userId={userId} onDeviceAdded={handleDeviceAdded} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default FleetManager;



// {/* <Grid container spacing={3} padding={2}>
//       {devices.map((device, index) => (
//         <Grid item xs={12} sm={6} md={4} lg={3} key={device.device_id}>
//           <Card sx={{ boxShadow: 3 }}>
//             <CardContent>
//               {/* Device Name */}
//               <Typography variant="h6" gutterBottom>
//                 {editDeviceId === device.device_id ? (
//                   <TextField
//                     value={editedDevice.device_name || ''}
//                     onChange={(e) => handleEditChange(e, 'device_name')}
//                     onKeyDown={(e) => handleKeyDown(e, device.device_id)}
//                   />
//                 ) : (
//                   device.device_name
//                 )}
//               </Typography>

//               {/* Status & Protocol */}
//               <Typography color="textSecondary">
//                 <strong>Status:</strong> 
//                 {pingStatus[device.device_id] === 'online' ? (
//                   <Typography component="span" color="success.main"> Connected </Typography>
//                 ) : pingStatus[device.device_id] === 'offline' ? (
//                   <Typography component="span" color="error.main"> Disconnected </Typography>
//                 ) : (
//                   <Typography component="span">Unknown</Typography>
//                 )}
//               </Typography>
//               <Typography color="textSecondary">
//                 <strong>Protocol:</strong> {device.protocol || 'N/A'}
//               </Typography>

//               {/* Additional Information */}
//               <Typography variant="body2" color="textSecondary">
//                 <strong>IP Address:</strong> {editDeviceId === device.device_id ? (
//                   <TextField
//                     value={editedDevice.ip_address || ''}
//                     onChange={(e) => handleEditChange(e, 'ip_address')}
//                     onKeyDown={(e) => handleKeyDown(e, device.device_id)}
//                   />
//                 ) : (
//                   device.ip_address
//                 )}
//               </Typography>
//               <Typography variant="body2" color="textSecondary">
//                 <strong>MAC ID:</strong> {editDeviceId === device.device_id ? (
//                   <TextField
//                     value={editedDevice.mac_id || ''}
//                     onChange={(e) => handleEditChange(e, 'mac_id')}
//                     onKeyDown={(e) => handleKeyDown(e, device.device_id)}
//                   />
//                 ) : (
//                   device.mac_id
//                 )}
//               </Typography>
//               <Typography variant="body2" color="textSecondary">
//                 <strong>Serial Number:</strong> {editDeviceId === device.device_id ? (
//                   <TextField
//                     value={editedDevice.serial_number || ''}
//                     onChange={(e) => handleEditChange(e, 'serial_number')}
//                     onKeyDown={(e) => handleKeyDown(e, device.device_id)}
//                   />
//                 ) : (
//                   device.serial_number
//                 )}
//               </Typography>
//               <Typography variant="body2" color="textSecondary">
//                 <strong>Created At:</strong> {new Date(device.created_at).toLocaleString()}
//               </Typography>

//               {/* Action Buttons */}
//               <Grid container spacing={1} justifyContent="space-between" marginTop={2}>
//                 <Grid item>
//                   <Tooltip title="Ping device" arrow>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={() => handlePing(device.ip_address, device.device_id)}
//                       sx={{ minWidth: 40, padding: 1, borderRadius: 1 }}
//                     >
//                       <NetworkPingIcon />
//                     </Button>
//                   </Tooltip>
//                 </Grid>

//                 <Grid item>
//                   {editDeviceId === device.device_id ? (
//                     <>
//                       <Tooltip title="Save" arrow>
//                         <IconButton color="secondary" onClick={() => handleSave(device.device_id)}>
//                           <SaveIcon />
//                         </IconButton>
//                       </Tooltip>
//                       <Tooltip title="Cancel" arrow>
//                         <IconButton color="secondary" onClick={handleCancel}>
//                           <CancelIcon />
//                         </IconButton>
//                       </Tooltip>
//                     </>
//                   ) : (
//                     <Tooltip title="Edit" arrow>
//                       <Button
//                         variant="contained"
//                         color="secondary"
//                         sx={{ minWidth: 40, padding: 1, borderRadius: 1 }}
//                         onClick={() => handleEdit(device.device_id, device)}
//                       >
//                         <EditIcon />
//                       </Button>
//                     </Tooltip>
//                   )}
//                 </Grid>

//                 <Grid item>
//                   <Tooltip title="Delete" arrow>
//                     <Button
//                       variant="contained"
//                       color="error"
//                       sx={{ minWidth: 40, padding: 1, borderRadius: 1 }}
//                       onClick={() => handleDelete(device.device_id)}
//                     >
//                       <DeleteIcon />
//                     </Button>
//                   </Tooltip>
//                 </Grid>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid> */}

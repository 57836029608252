import React from 'react';
import { Box, IconButton, Typography, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const OpenInfoModal = ({ open, onClose, theme }) => {

    return (
        <Modal
          open={open}
          onClose={onClose} // Ensure this matches the prop name
          aria-labelledby="info-modal-title"
          aria-describedby="info-modal-description"
          sx={{
            backdropFilter: "blur(6px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "16px",
              border: `1px solid ${theme.palette.divider}`,
              position: "relative",
              overflow: "hidden",
              "@media (max-width:600px)": {
                width: "90%",
                p: 2,
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={onClose} // Ensure this matches the prop name
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: theme.palette.grey[600],
                "&:hover": {
                  color: theme.palette.primary.main,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: theme.palette.primary.main,
                display: "flex",
                alignItems: "center",
                mb: 2,
                "@media (max-width:600px)": {
                  fontSize: "1.5rem",
                },
              }}
            >
              <InfoIcon
                sx={{ marginRight: 1, fontSize: "inherit", paddingBottom: 0.5 }}
              />
              Information
            </Typography>
            <Typography
              variant="body1"
              id="info-modal-description"
              sx={{
                textAlign: "justify",
                lineHeight: 1.6,
                mb: 2,
                color: "black",
                "@media (max-width:600px)": {
                  fontSize: "0.875rem",
                },
              }}
            >
              CIMCopilot is an intelligent digital assistant designed to
              facilitate the setup, installation, and configuration of the CIM10
              IoT Edge gateway. By leveraging advanced technologies in
              artificial intelligence (AI), machine learning (ML), and natural
              language processing (NLP), CIMCopilot aims to streamline complex
              technical processes, making them accessible to users regardless of
              their technical expertise.
              <br />
              <br />
              For more information, please contact our support team at:{" "}
              <a
                href="mailto:support@cimcondigital.com"
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                }}
              >
                support@cimcondigital.com
              </a>
            </Typography>
          </Box>
        </Modal>
    );
};

export default OpenInfoModal;

import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
// import { makeStyles } from '@mui/styles';
import { useChat } from '../../context/ChatContext';


const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#C0EAEB", // ThingsBoard secondary color
    },
    // background: {
    //   default: '#F4F6F9', // Light background color
    // },
    // text: {
    //   primary: '#333333', // Dark text color
    // }
  },
});




const NewChatButton = ({ onNewChat }) => {
  return (
    <ThemeProvider theme={theme}>
    <Tooltip title="New Chat" arrow>
      <Button 
        variant="contained" 
        onClick={onNewChat}
        sx={{
          // margin: "1px x 0px",  // Match the other buttons' margins
          backgroundColor: theme.palette.secondary.main,
          fontWeight: "bold",
          width: "100%",  // Ensure width matches the other buttons
          justifyContent: "flex-start", // Align content to the left
          textAlign: "left",
          paddingLeft: "5px",  // Match the padding of other buttons
          // borderRadius: "5px",  // Match border-radius
          boxShadow: 3,  // Add the same shadow effect as other buttons
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        }}
      >
        <img src="post.png" style={{ height: "24px", width: "24px", paddingRight: "10px" }} />
        New Chat
      </Button> 
    </Tooltip>
  </ThemeProvider>
  );
};

export default NewChatButton;

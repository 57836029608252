

// import React, { useState } from 'react';
// import { Box, Button, TextField, Modal, Typography, IconButton, Avatar, Grid } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';

// const ProfileModal = ({ open, handleClose, theme, user }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [formData, setFormData] = useState({
//     name: user.name,
//     mobile_number: user.mobile_number,
//     company_name: user.company_name,
//     designation: user.designation,
//     email: user.email
//   });

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleEdit = () => {
//     setIsEditing(true);
//   };

//   const handleSave = async () => {
//     const { email, mobile_number,  ...dataToUpdate } = formData; // Exclude email from update data
//     try {
//       await axios.patch(`${process.env.REACT_APP_PUBLIC_URL}/api/users/update/${user.id}/`, dataToUpdate);
//       alert('Details updated successfully!');
//       setIsEditing(false);
//     } catch (error) {
//       console.error('Error updating details:', error);
//       alert('Error updating details.');
//     }
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-title"
//       aria-describedby="modal-description"
//       sx={{ backdropFilter: "blur(4px)" }}
//     >
//       <Box
//         sx={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           width: "90%",
//           maxWidth: "600px",
//           bgcolor: "background.paper",
//           boxShadow: 4,
//           p: 4,
//           borderRadius: "10px",
//           border: `1px solid ${theme.palette.divider}`,
//         }}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: "absolute",
//             right: 16,
//             top: 16,
//             color: theme.palette.grey[600],
//             "&:hover": {
//               color: theme.palette.primary.main,
//               backgroundColor: "rgba(0, 0, 0, 0.2)",
//             },
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography variant="h5" gutterBottom sx={{ color: 'primary.main' }} id="modal-title">
//           My Profile
//         </Typography>
//         <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
//           <Avatar
//             alt={user.name}
//             src="/static/images/avatar/1.jpg"
//             sx={{
//               width: 56,
//               height: 56,
//               mr: 2,
//               backgroundColor: theme.palette.primary.main,
//             }}
//           />
//           <Box>
//             <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//               {user.name}
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               {user.designation}
//             </Typography>
//           </Box>
//         </Box>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Mobile Number"
//               name="mobile_number"
//               value={formData.mobile_number}
//               onChange={handleChange}
//               fullWidth
//               InputProps={{
//                 // readOnly: !isEditing,
//                 readOnly: true,
//                 style: { userSelect: 'none' },
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Email"
//               name="email"
//               value={formData.email}
//               fullWidth
//               InputProps={{
//                 readOnly: true,
//                 style: { userSelect: 'none' },
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Company Name"
//               name="company_name"
//               value={formData.company_name}
//               onChange={handleChange}
//               fullWidth
//               InputProps={{
//                 readOnly: !isEditing,
//                 style: { userSelect: 'none' },
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Designation"
//               name="designation"
//               value={formData.designation}
//               onChange={handleChange}
//               fullWidth
//               InputProps={{
//                 readOnly: !isEditing,
//                 style: { userSelect: 'none' },
//               }}
//               variant="outlined"
//             />
//           </Grid>
//         </Grid>
//         <Box mt={3} display="flex" justifyContent="flex-end">
//           {isEditing ? (
//             <>
//               <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 2 }}>
//                 Save
//               </Button>
//               <Button variant="outlined" color="secondary" onClick={() => setIsEditing(false)}>
//                 Cancel
//               </Button>
//             </>
//           ) : (
//             <Button variant="contained" color="primary" onClick={handleEdit}>
//               Edit
//             </Button>
//           )}
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default ProfileModal;


// import React, { useState, useEffect } from 'react';
// import { Box, Button, TextField, Modal, Typography, IconButton, Avatar, Grid } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';

// const ProfileModal = ({ open, handleClose, theme, user }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [formData, setFormData] = useState({
//     name: "",
//     designation: "",
//     company_name: "",
//   });
//   const [error, setError] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");

//   useEffect(() => {
//     // Pre-fill form data with user details when the modal is opened
//     if (user) {
//       setFormData({
//         name: user.name,
//         designation: user.designation,
//         company_name: user.company_name,
//       });
//     }
//   }, [user]);

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSave = async () => {
//     try {
//       // Log the data being sent
//       console.log("Data being sent:", formData);
      
//       const response = await axios.patch(`${process.env.REACT_APP_PUBLIC_URL}/api/users/update/${user.id}/`, formData);
      
//       // Log the response to ensure it is received and processed
//       console.log("Response received:", response);
  
//       // If the response is successful, proceed with the UI updates
//       if (response.status === 200) {
//         setSuccessMessage("Profile updated successfully.");
//         setIsEditing(false);
//       } else {
//         // Handle unexpected statuses
//         setError("Unexpected response status.");
//       }
//     } catch (error) {
//       // Log the error to see more details about the failure
//       console.error("Error occurred:", error);
      
//       // Update the UI with the error message
//       setError("An error occurred while updating the profile.");
//     }
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-title"
//       aria-describedby="modal-description"
//       sx={{ backdropFilter: "blur(4px)" }}
//     >
//       <Box
//         sx={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           width: "90%",
//           maxWidth: "600px",
//           bgcolor: "background.paper",
//           boxShadow: 4,
//           p: 4,
//           borderRadius: "10px",
//           border: `1px solid ${theme.palette.divider}`,
//         }}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: "absolute",
//             right: 16,
//             top: 16,
//             color: theme.palette.grey[600],
//             "&:hover": {
//               color: theme.palette.primary.main,
//               backgroundColor: "rgba(0, 0, 0, 0.2)",
//             },
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography variant="h5" gutterBottom sx={{ color: 'primary.main' }} id="modal-title">
//           My Profile
//         </Typography>
//         <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
//           <Avatar
//             alt={user.name}
//             src="/static/images/avatar/1.jpg"
//             sx={{
//               width: 56,
//               height: 56,
//               mr: 2,
//               backgroundColor: theme.palette.primary.main,
//             }}
//           />
//           <Box>
//             <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//               {user.name}
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               {user.designation}
//             </Typography>
//           </Box>
//         </Box>
//         <Grid container spacing={2}>
//         <Grid item xs={12}>
//             <TextField
//               label="Name"
//               name="name"
//               value={formData.name}
//               fullWidth
//               onChange={handleInputChange}
//               InputProps={{
//                 readOnly: !isEditing,
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Mobile Number"
//               value={user.mobile_number}
//               fullWidth
//               InputProps={{
//                 readOnly: true,
//                 style: { userSelect: 'none' },
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Email"
//               value={user.email}
//               fullWidth
//               InputProps={{
//                 readOnly: true,
//                 style: { userSelect: 'none' },
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Company Name"
//               name="company_name"
//               value={formData.company_name}
//               fullWidth
//               onChange={handleInputChange}
//               InputProps={{
//                 readOnly: !isEditing,
//               }}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Designation"
//               name="designation"
//               value={formData.designation}
//               fullWidth
//               onChange={handleInputChange}
//               InputProps={{
//                 readOnly: !isEditing,
//               }}
//               variant="outlined"
//             />
//           </Grid>
          
//         </Grid>
//         <Box mt={3} display="flex" justifyContent="flex-end">
//           {isEditing ? (
//             <>
//               <Button variant="contained" color="primary" onClick={handleSave}>
//                 Save
//               </Button>
//               <Button variant="outlined" color="secondary" onClick={() => setIsEditing(false)} sx={{ ml: 2 }}>
//                 Cancel
//               </Button>
//             </>
//           ) : (
//             <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
//               Edit
//             </Button>
//           )}
//         </Box>
//         {error && <Typography color="error" mt={2}>{error}</Typography>}
//         {successMessage && <Typography color="success" mt={2}>{successMessage}</Typography>}
//       </Box>
//     </Modal>
//   );
// };

// export default ProfileModal;



import React from 'react';
import { Box, Button, TextField, Modal, Typography, IconButton, Avatar, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ProfileModal = ({ open, handleClose, theme, user }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ backdropFilter: "blur(4px)" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "600px",
          bgcolor: "background.paper",
          boxShadow: 4,
          p: 4,
          borderRadius: "10px",
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: theme.palette.grey[600],
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom sx={{ color: 'primary.main' }} id="modal-title">
          My Profile
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Avatar
            alt={user.name}
            src="/static/images/avatar/1.jpg"
            sx={{
              width: 56,
              height: 56,
              mr: 2,
              backgroundColor: theme.palette.primary.main,
            }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {user.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {user.designation}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              value={user.name}
              fullWidth
              // onChange={handleInputChange}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile Number"
              value={user.mobile_number}
              fullWidth
              InputProps={{
                readOnly: true,
                style: { userSelect: 'none' },
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              value={user.email}
              fullWidth
              InputProps={{
                readOnly: true,
                style: { userSelect: 'none' },
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Company Name"
              value={user.company_name}
              fullWidth
              InputProps={{
                readOnly: true,
                style: { userSelect: 'none' },
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Designation"
              value={user.designation}
              fullWidth
              InputProps={{
                readOnly: true,
                style: { userSelect: 'none' },
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{fontWeight:"bold", color:""}}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProfileModal;


